export const DRAFTS = 'Drafts';
export const SAVED_TEMPLATES = 'Saved Templates';
export const SHARED_TEMPLATES = 'Shared Templates';
export const SAVED_AUDIENCES = 'Audiences';

export const SAVED_TEMPLATES_TAB = 'savedTemplates';
export const SHARED_TEMPLATES_TAB = 'sharedTemplates';
export const AUDIENCES_TAB = 'audiences';
export const DRAFTS_TAB = 'drafts';

export const ACTIVE = { name: 'Active', value: 'active' };
export const SCHEDULED = { name: 'Scheduled', value: 'scheduled' };
export const ARCHIVED = { name: 'Archived', value: 'archived' };
export const FAVOURITED = { name: 'Favorited', value: 'starred' };
export const SENT = { name: 'Sent', value: 'sent' };
export const NAME = { name: 'Name', value: 'name' };
export const DATE = { name: 'Date', value: '-saveTime' };

export const ROWS_TO_LOAD = 5;
