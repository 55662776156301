import React, { Component } from 'react';
import './OptinlogsTable.css';
import '../../globalcss.css';
import { Container, Title } from './OptinTable.styled';
import { Box } from 'grommet';
import { Row, Col, Drawer } from 'antd';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actioncreators from '../../redux/action';
import { authUserHelper } from '../../redux/helpers/index';
import _union from 'underscore-es/union';
import * as moment from 'moment';
import { contactsWithGroups } from '../../redux/services/contact';
import {
  getOrganizationTopics,
  fetchOrgGroups,
} from '../../redux/services/groups';
import * as services from '../../redux/services';
import MaterialTable, { MTableCell } from 'material-table';

class OptinlogsTable extends Component {
  constructor(props) {
    super(props);
    moment.locale('en');
    this.state = {
      memberList: [],
      logs: [],
      log: {},
      selectedUser: {},
      tagLookup: {},
      pageSize: parseInt(localStorage.getItem('pageSize'), 10) || 10,
    };
  }

  componentDidMount() {
    authUserHelper(this.props);

    if (
      this.props.organization.selected &&
      this.props.organization.selected.id
    ) {
      this.getMembers(this.props.organization.selected.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !Object.is(
        prevProps.organization.selected,
        this.props.organization.selected
      )
    ) {
      this.getMembers(this.props.organization.selected.id);
    }
  }

  showpersonaltestDrawer = data => {
    this.getLogsForMember(data);
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  showdetailsDrawer = async data => {
    if (data.savedBy) {
      const userInfo = await services.getUser(data.savedBy);
      if (!userInfo.error) {
        data.savedBy = userInfo;
        this.setState({
          detailsDrawer: true,
          log: data,
        });
      }
    } else {
      this.setState({
        detailsDrawer: true,
        log: data,
      });
    }
  };
  ondetailsDrawerClose = () => {
    this.setState({
      detailsDrawer: false,
    });
  };

  getLogsForMember = async data => {
    this.setState({ logs: [] });
    this.props.actions.fullloader(true, 'Loading Logs...');
    const logsRes = await services.getOptinLogs(data.id);
    if (!logsRes.err) {
      let logArray = [];
      let totalLogs = logsRes.length;
      if (totalLogs < 1) {
        this.props.actions.toast('info', 'No Logs Found For This Member.');
        this.props.actions.fullloader(false);
        return;
      }
      const tagLookup = this.state.tagLookup;
      for (let log of logsRes) {
        log.member = data;
        log.tags = [];
        let logTags = _union(log.textTags, log.emailTags);
        if (!logTags || logTags.length === 0) {
          if (typeof log.createdOn === 'number') {
            log.date = moment.unix(log.createdOn).format('YYYY-MM-DD hh:mm A');
            logArray.push(log);
          }
        }
        for (let i = 0; i < logTags.length; i++) {
          if (tagLookup[logTags[i]]) {
            log.tags.push(tagLookup[logTags[i]]);
          }
        }
        if (typeof log.createdOn === 'number') {
          log.date = moment.unix(log.createdOn).format('YYYY-MM-DD hh:mm A');
          logArray.push(log);
        }
      }
      this.setState({ logs: logArray });
      this.props.actions.fullloader(false);
    }
  };

  getMembers = async orgId => {
    if (orgId) {
      let taglookup = {};
      this.props.actions.fullloader(true, 'Loading Members...');
      const tags = await fetchOrgGroups(orgId);
      const topics = await getOrganizationTopics(orgId);
      if (!tags.error && !topics.error) {
        if (tags.length < 1 && topics.length < 1) {
          this.setState({ tagLookup: {} });
          return;
        }
        for (let tag of tags) {
          taglookup[tag.id] = tag.name;
        }
        for (let topic of topics) {
          taglookup[topic.id] = topic.name;
        }
        this.setState({ tagLookup: taglookup });
      }

      const memberOptions = {
        orgId: orgId,
      };
      const members = await contactsWithGroups(memberOptions);
      if (!members.error) {
        if (members.length < 1) {
          this.props.actions.fullloader(false);
          return;
        }
        let memberList = await members.map(member => {
          let tempTags = _union(
            member.identifiers,
            member.emailTags,
            member.textTags
          );
          let memberTags = [];
          for (let tag of tempTags) {
            if (taglookup[tag]) {
              memberTags.push(tag);
            }
          }
          member.tags = memberTags;
          return member;
        });
        this.props.actions.fullloader(false);
        this.setState({
          memberList: memberList,
        });
      }
    }
  };

  render() {
    return (
      <Container>
        <Title>Preference Logs</Title>
        <Box className="noterouter_managetable btnseparation">
          <MaterialTable
            components={{
              Cell: props => <MTableCell {...props} />,
            }}
            columns={[
              {
                title: 'First Name',
                field: 'firstName',
                defaultSort: 'asc',
              },
              { title: 'Last Name', field: 'lastName' },
              {
                title: 'Organization',
                field: '',
                render: () => {
                  return (
                    <p>{this.props.organization.selected.organizationName}</p>
                  );
                },
              },
              {
                title: 'Tags Assigned',
                field: 'tags',
                padding: 'dense',
                lookup: this.state.tagLookup,
                customFilterAndSearch: (text, rowData, column) => {
                  let name = rowData.firstName + ' ' + rowData.lastName;
                  let filter = Object.keys(column.lookup).filter(lookup => {
                    return column.lookup[lookup]
                      .toLowerCase()
                      .includes(text.toLowerCase());
                  });
                  let intersection = rowData.tags.filter(x =>
                    filter.includes(x)
                  );
                  return (
                    (name && name.toLowerCase().includes(text.toLowerCase())) ||
                    (rowData.lastName &&
                      rowData.lastName
                        .toLowerCase()
                        .includes(text.toLowerCase())) ||
                    (this.props.organization.selected &&
                      this.props.organization.selected.organizationName &&
                      this.props.organization.selected.organizationName
                        .toLowerCase()
                        .includes(text.toLowerCase())) ||
                    intersection.length > 0
                  );
                },
                render: rowData => {
                  return rowData.tags.map((tag, index) => {
                    return (
                      <p className="chipone" key={index}>
                        {this.state.tagLookup[tag]}
                      </p>
                    );
                  });
                },
              },
            ]}
            data={this.state.memberList}
            className="Manage_materialtable"
            actions={[]}
            options={{
              showTitle: false,
              padding: 'dense',
              search: true,
              pageSize: this.state.pageSize,
              headerStyle: {
                fontWeight: 'bold',
              },
              pageSizeOptions: [10, 20, 50],
            }}
            onRowClick={(e, data) => this.showpersonaltestDrawer(data)}
            onChangeRowsPerPage={e => {
              localStorage.setItem('pageSize', e);
              this.setState({ pageSize: e });
            }}
          />
          {this.state.selectedUser && (
            <Drawer
              title="Event List"
              width={800}
              onClose={this.onClose}
              visible={this.state.visible}
              style={{
                overflow: 'auto',
                paddingLeft: '0',
                paddingRight: '0',
              }}
            >
              <div className="ManagePortalUserscomp ant-drawerpart">
                <Box overflow="auto">
                  <div className="middlePart">
                    <Box className="noterouter_managetable btnseparation">
                      <MaterialTable
                        components={{
                          Cell: props => <MTableCell {...props} />,
                        }}
                        columns={[
                          {
                            title: 'Date/Time ',
                            field: 'date',
                            defaultSort: 'desc',
                            render: rowData => {
                              return <p>{rowData.date}</p>;
                            },
                          },
                          {
                            title: 'Member',
                            field: 'firstName',
                            render: rowData => {
                              return (
                                <p>
                                  {rowData.member.firstName +
                                    ' ' +
                                    rowData.member.lastName}
                                </p>
                              );
                            },
                          },
                          {
                            title: 'Organization',
                            field: '',
                            render: () => {
                              return (
                                <p>
                                  {
                                    this.props.organization.selected
                                      .organizationName
                                  }
                                </p>
                              );
                            },
                          },
                          {
                            title: 'Tags Assigned',
                            field: 'tags',
                            render: rowData => {
                              return rowData.tags.map((tag, index) => {
                                return (
                                  <p className="chipone" key={index}>
                                    {tag}
                                  </p>
                                );
                              });
                            },
                          },
                        ]}
                        data={this.state.logs}
                        className="Manage_materialtable"
                        title=""
                        actions={[]}
                        options={{
                          pageSize: this.state.pageSize,
                          pageSizeOptions: [10, 20, 50],
                        }}
                        onChangeRowsPerPage={e => {
                          localStorage.setItem('pageSize', e);
                          this.setState({ pageSize: e });
                        }}
                        onRowClick={(e, data) => this.showdetailsDrawer(data)}
                      />

                      <Drawer
                        title="Event Details"
                        className="detailsSection"
                        width={400}
                        onClose={this.ondetailsDrawerClose}
                        visible={this.state.detailsDrawer}
                      >
                        {this.state.log && this.state.log.member && (
                          <div>
                            <Row>
                              <p className="detailspart">
                                <span className="detailslabel">Date : </span>
                                <span className="detailscontent">
                                  {moment
                                    .unix(this.state.log.createdOn)
                                    .format('MM-DD-YYYY hh:mm A')}
                                </span>
                              </p>
                            </Row>
                            <Row>
                              <p className="detailspart">
                                <span className="detailslabel">Name : </span>
                                <span className="detailscontent">
                                  {this.state.log.member.firstName +
                                    ' ' +
                                    this.state.log.member.lastName}
                                </span>
                              </p>
                            </Row>
                            <Row>
                              <p className="detailspart">
                                <span className="detailslabel">
                                  Email Id :{' '}
                                </span>
                                <span className="detailscontent">
                                  {this.state.log.member.email}
                                </span>
                              </p>
                            </Row>
                            <Row>
                              <p className="detailspart">
                                <span className="detailslabel">
                                  Phone Number :{' '}
                                </span>
                                <span className="detailscontent">
                                  {this.state.log.member.phoneNumber}
                                </span>
                              </p>
                            </Row>
                            <Row>
                              <p className="detailspart">
                                <span className="detailslabel">Message : </span>
                                <span className="detailscontent">
                                  {this.state.log.message}
                                </span>
                              </p>
                            </Row>
                            {this.state.log.savedBy && (
                              <Row>
                                <p className="detailspart">
                                  <span className="detailslabel">
                                    Saved By :{' '}
                                  </span>
                                  <span className="detailscontent">
                                    {this.state.log.savedBy.firstName +
                                      ' ' +
                                      this.state.log.savedBy.lastName}
                                  </span>
                                </p>
                              </Row>
                            )}
                            <Row>
                              <p className="detailspart">
                                <span className="detailslabel">Status : </span>
                                <span className="detailscontent statuschip">
                                  {this.state.log.status}
                                </span>
                              </p>
                            </Row>

                            {this.state.log.tags.map(tag => {
                              if (tag.delete === false) {
                                return (
                                  <div key={tag.id}>
                                    <Row>
                                      <Col span={24} className="tagsec">
                                        <span className="detailslabel taghead">
                                          {tag.name + ' '}
                                        </span>

                                        <p className="detailspart m0">
                                          <span className="detailslabel">
                                            Email :{' '}
                                          </span>
                                          {this.state.log.emailTags.indexOf(
                                            tag.id
                                          ) > -1 ? (
                                            <span
                                              className="detailscontent"
                                              style={{ color: '#06c306' }}
                                            >
                                              Yes
                                            </span>
                                          ) : (
                                            <span
                                              className="detailscontent"
                                              style={{
                                                color:
                                                  'rgba(255, 0, 0, 0.7490196078431373)',
                                              }}
                                            >
                                              No
                                            </span>
                                          )}
                                          <span className="textpart">
                                            <span className="detailslabel">
                                              Text :{' '}
                                            </span>
                                            {this.state.log.textTags.indexOf(
                                              tag.id
                                            ) > -1 ? (
                                              <span
                                                className="detailscontent"
                                                style={{ color: '#06c306' }}
                                              >
                                                Yes
                                              </span>
                                            ) : (
                                              <span
                                                className="detailscontent"
                                                style={{
                                                  color:
                                                    'rgba(255, 0, 0, 0.7490196078431373)',
                                                }}
                                              >
                                                No
                                              </span>
                                            )}
                                          </span>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        )}
                      </Drawer>
                    </Box>
                  </div>
                </Box>
              </div>
            </Drawer>
          )}
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return state;
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OptinlogsTable)
);
