import { useEffect, useState } from 'react';

const useCountCardsPerRow = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const resetWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', resetWindowWidth);

    return () => {
      window.removeEventListener('resize', resetWindowWidth);
    };
  }, []);

  let numOfCardsPerRow = 2;

  if (windowWidth >= 3240) {
    numOfCardsPerRow = 6;
  } else if (windowWidth >= 2750) {
    numOfCardsPerRow = 5;
  } else if (windowWidth >= 2260) {
    numOfCardsPerRow = 4;
  } else if (windowWidth >= 1770) {
    numOfCardsPerRow = 3;
  }
  return numOfCardsPerRow;
};

export default useCountCardsPerRow;
