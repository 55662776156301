import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useIsFeatureEnabled } from '../../../../libs/feature-toggles/react';
import Feature from '../../../../infrastructure/features';
import { saveNewTemplate, userUpdate } from '../../../../redux/services';
import {
  selectedOrganizationSelector,
  userDetailSelector,
} from '../../../../redux/selector';
import { Button, Menu } from './MenuContent.styled';
import { userdetail, toast, fullloader } from '../../../../redux/action';
import SaveAsDesignModal from '../../Modals/SaveAsDesignModal';
import ConfirmOverwriteModal from '../../Modals/ConfirmOverwriteModal';

const MenuContent = ({
  data = {},
  shouldIncludeApply = false,
  initialName,
  onSaveSuccess,
  shouldIncludeABToggle,
  isABToggleEnabled,
  setABToggleEnabled,
  setConfirmDeleteABDraftModalVisible,
  handleVersionChange,
  beeEditor,
  isBeeLoading,
}) => {
  const isABFeatureEnabled = useIsFeatureEnabled(Feature.AB);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const organizationId = useSelector(selectedOrganizationSelector).id;
  const user = useSelector(userDetailSelector);
  const { draft: draftId } = queryString.parse(location.search);

  const [isSaveAsDesignModalVisible, setSaveAsDesignModalVisible] =
    useState(false);
  const [isConfirmOverwriteModalVisible, setConfirmOverwriteModalVisible] =
    useState(false);
  const [isToggleStructureOn, setToggleStructure] = useState(
    user.isEditorStructureOutlinesVisible
  );

  useEffect(() => {
    const toggleStructure = async () => {
      if (isBeeLoading === false && user.isEditorStructureOutlinesVisible) {
        await beeEditor.toggleStructure();
      }
    };

    if (user) {
      setToggleStructure(user.isEditorStructureOutlinesVisible);
      toggleStructure();
    }
  }, [isBeeLoading]);

  const saveAsNewTemplate = async name => {
    try {
      dispatch(fullloader(true, 'Saving a new template...'));
      const template = {
        archived: false,
        name: name || 'Untitled',
        organizationId,
        userId: user.id,
        draftId,
        ...data,
      };

      await saveNewTemplate(template);
      onSaveSuccess();
      setSaveAsDesignModalVisible(false);
      dispatch(fullloader(false));
      dispatch(toast('success', `${template.name} saved`));
    } catch (e) {
      dispatch(toast('error', 'Error saving Template.'));
    }
  };

  const getABText = () => {
    return isABToggleEnabled ? 'Remove A/B Mode' : 'Activate A/B Mode';
  };

  const toggleBeeEditorSturcture = async () => {
    const updatedToggleStructure = !isToggleStructureOn;
    setToggleStructure(updatedToggleStructure);
    dispatch(
      userdetail({
        ...user,
        isEditorStructureOutlinesVisible: updatedToggleStructure,
      })
    );
    await beeEditor.toggleStructure();
    await userUpdate({
      id: user.id,
      isEditorStructureOutlinesVisible: updatedToggleStructure,
    });
  };

  const apply = () =>
    history.push(
      `/dashboard/drafts?apply=1&tab=savedTemplates&draft=${draftId}`
    );

  const fileMenuContent = (
    <Menu>
      <Menu.Item key="save" onClick={() => setSaveAsDesignModalVisible(true)}>
        Save Template
      </Menu.Item>
      {isABFeatureEnabled && shouldIncludeABToggle && (
        <Menu.Item
          key="enableAB"
          onClick={async () => {
            if (isABToggleEnabled) {
              setConfirmDeleteABDraftModalVisible(true);
            } else {
              setABToggleEnabled(true);
              await handleVersionChange('A');
            }
          }}
        >
          {getABText()}
        </Menu.Item>
      )}
      <Menu.Item key="toggleStructure" onClick={toggleBeeEditorSturcture}>
        {isToggleStructureOn ? 'Hide Structure' : 'Show Structure'}
      </Menu.Item>
      {shouldIncludeApply && (
        <Menu.Item
          key="apply"
          onClick={() => setConfirmOverwriteModalVisible(true)}
        >
          Apply Template
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={fileMenuContent}>
        <Button style={{ width: '110px' }}>
          Actions <DownOutlined type="down" />
        </Button>
      </Dropdown>
      {isConfirmOverwriteModalVisible && (
        <ConfirmOverwriteModal
          onHide={() => setConfirmOverwriteModalVisible(false)}
          apply={apply}
        />
      )}
      {isSaveAsDesignModalVisible && (
        <SaveAsDesignModal
          initialName={`${initialName} Template`}
          onHide={() => setSaveAsDesignModalVisible(false)}
          saveAsNewTemplate={saveAsNewTemplate}
        />
      )}
    </>
  );
};

MenuContent.defaultProps = {
  onSaveSuccess: () => {},
};

export default MenuContent;
