import React from 'react';
import { Select } from './select.styled';

const SelectDropDown = ({ menuItemNames, onChange }) => {
  return (
    <Select
      style={{
        width: '80px',
        color: 'black',
      }}
      dropdownStyle={{ minWidth: '185px' }}
      placeholder="File"
      onChange={onChange}
      value={null}
      size="large"
    >
      {menuItemNames.map(menuItemName => (
        <Select.Option key={menuItemName} value={menuItemName}>
          {menuItemName}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectDropDown;
