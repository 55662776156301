import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useStableCallback } from '../../libs/react-use-extra';
import { customFonts } from './BEE_fonts';
import { GroupPermissionModal } from './groupPermissionModal';
import newTemplate from './newTemplateFile';

const CLIENT_ID = `${process.env.REACT_APP_BEE_CLIENT}`;
const CLIENT_SECRET = `${process.env.REACT_APP_BEE_SECRET}`;

export default function Bee({
  clientUid,
  onLoad,
  onSave,
  onChange,
  beeEditor,
  className,
  currentDesign,
  onComment,
  onSend,
  AB,
}) {
  const [open, setOpen] = useState(false);
  const displayResolution = useRef(null);

  const { userHandle, username, userColor, beeAdmin } = useSelector(state => ({
    userHandle: state.user.detail._id,
    username: `${state.user.detail.firstName} ${state.user.detail.lastName}`,
    userColor: state.user.detail.avatarColor,
    beeAdmin: state.user.userPrivileges?.beeAdmin,
  }));

  const editorFonts = {
    showDefaultFonts: true,
    customFonts: customFonts,
  };

  const onCloseConditionDialog = () => {
    setOpen(false);
    displayResolution.current?.reject();
  };

  const saveDisplayConditions = (groupIds, groupNames) => {
    if (!groupIds?.length) displayResolution.current?.reject();
    displayResolution.current?.resolve({
      type: 'group',
      label: 'Applied Group Filters',
      description: groupNames?.join(', ') ?? '',
      before: `{{#if (subscribedToGroups '${groupIds.join(',')}') }}`,
      after: '{{/if}}',
    });
    setOpen(false);
  };

  const beeConfig = {
    uid: clientUid,
    container: 'bee-plugin-container',
    language: 'en-US',
    roleHash: beeAdmin ? 'nr_admin_0' : 'nr_staff_0',
    username,
    userColor,
    userHandle,
    trackChanges: true,
    contentDialog: {
      rowDisplayConditions: {
        label: 'Apply Group Filters',
        handler: (resolve, reject) => {
          setOpen(true);
          displayResolution.current = { resolve, reject };
        },
      },
    },
    editorFonts,
    onSave: useStableCallback(onSave),
    onChange: useStableCallback(onChange),
    onLoad: useStableCallback(onLoad),
    onComment: useStableCallback(onComment),
    onSend: useStableCallback(onSend),
    onError: error => console.error('bee editor error:', error),
    onWarning: warning => console.warn('bee editor warning:', warning),
  };

  const loadEditor = async () => {
    const loadTemplate =
      !currentDesign?.page || currentDesign === '{}'
        ? newTemplate
        : currentDesign;

    await beeEditor.start(beeConfig, loadTemplate);
  };

  useEffect(() => {
    beeEditor
      .getToken(CLIENT_ID, CLIENT_SECRET)
      .then(loadEditor)
      .catch(error => {
        console.error('Error in loading bee editor', error);
      });
  }, []);

  useEffect(() => {
    // reloads editor when AB version changes
    if (AB) {
      loadEditor();
    }
  }, [AB]);

  return (
    <>
      <div id="bee-plugin-container" className={className} />
      <GroupPermissionModal
        isOpen={open}
        handleClose={onCloseConditionDialog}
        onSave={saveDisplayConditions}
      />
    </>
  );
}
