import './Manage.css';

import { Button, Drawer, Menu, MenuItem } from '@material-ui/core';
import { createTheme, darken, lighten } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid';
import { Form, Popover, Tag, Tooltip } from 'antd';
import { Box, Grommet, Tab, Tabs } from 'grommet';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// Packages:
import * as Papa from 'papaparse/papaparse.min.js';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import ReactFileReader from 'react-file-reader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { isUnion } from '../../utils';

// Redux:
import * as actioncreators from '../../redux/action';
import { useToast, useUpdateUIState } from '../../redux/action';
import { escapeRegExp, modifyWord } from '../../redux/helpers/helpers';
import { authUserHelper, gAnalytics } from '../../redux/helpers/index';
import {
  csvImportMembers,
  deleteContactsFromOrg,
  deleteSelectedGroups,
  fetchOrgTopicsAndGroups,
  getContactById,
  getTagTypes,
  getManageTableContacts,
  updateTagsSubscriberCounts,
  updateTopicsSubscriberCounts,
  getAllOrgNotes,
  updateOrganization,
  getColumnsVisibility,
} from '../../redux/services.js';
import FieldManager from '../CustomFields/FieldManager';
// Components:
import ManageAddForm from '../ManageAddform/ManageAddForm';
import DeleteMemberModal from './DeleteMemberModal';
import DeleteTagModal from './DeleteTagModal';
import SearchBar from './SearchBar';
import TagsTable from './TagsTable';
import CSVUploadContact from './UploadContactDrawer';
import { ChildDrawer } from '../ChildDrawer';
import BulkGroupAssignmentButton from '../BulkGroupAssignmentButton';
import AMSSync from './AMSSync';
import AMSSyncAll from './AMSSyncAll.js';
import { Container, Wrapper, RightTopContainer } from './Manage.styled';

const columnData = [
  {
    headerName: 'First Name',
    field: 'firstName',
    minWidth: 110,
    flex: 0.8,
  },
  { headerName: 'Last Name', field: 'lastName', minWidth: 110, flex: 1 },
  {
    headerName: 'Phone',
    field: 'phoneNumber',
    minWidth: 125,
    flex: 0.65,
    // eslint-disable-next-line react/display-name
    renderCell: params => {
      return params.row.smsAlert ? (
        <Tooltip title="This phone number is experiencing delivery issues. Check Analytics for more details.">
          <span className={'alertFlagIcon'}>
            <ExclamationCircleOutlined
              style={{
                color: 'red',
              }}
            />
            &nbsp;&nbsp;{params.row.phoneNumber}
          </span>
        </Tooltip>
      ) : (
        params.row.phoneNumber
      );
    },
  },
  {
    headerName: 'Email',
    field: 'email',
    minWidth: 150,
    flex: 1.35,
    // eslint-disable-next-line react/display-name
    renderCell: params => {
      return params.row.spamAlert || params.row.bounceAlert ? (
        <Tooltip title="This email is experiencing delivery issues. Check Analytics for more details.">
          <span className={'alertFlagIcon'}>
            <ExclamationCircleOutlined
              style={{
                color: 'red',
              }}
            />
            &nbsp;&nbsp;{params.row.email}
          </span>
        </Tooltip>
      ) : (
        params.row.email
      );
    },
  },
  {
    headerName: 'Member #',
    field: 'memberId',
    minWidth: 125,
    flex: 0.6,
  },
  {
    headerName: 'NRDS #',
    field: 'memberNRDSID',
    minWidth: 125,
    flex: 0.6,
  },
  {
    headerName: 'Groups',
    field: 'tags',
    minWidth: 90,
    flex: 0.5,
    // eslint-disable-next-line react/display-name
    renderCell: params => {
      return (
        <Popover
          placement="left"
          className=""
          content={
            <div>
              {params.row.tags.map(tag => {
                return (
                  <p className={'eachTag'} key={tag}>
                    {tag}
                  </p>
                );
              })}
            </div>
          }
        >
          <Tag color="grey" className="manage-member-tag">
            {params.row.tags.length}{' '}
            {`${params.row.tags.length === 1 ? 'Group' : 'Groups'}`}
          </Tag>
        </Popover>
      );
    },
  },
];

const Manage = props => {
  const csvLink = useRef();
  const [index, setIndex] = useState(0);
  const [tagList, setTagList] = useState([]);
  const [tagDetail, setTagDetail] = useState([]);
  const [organizationTopics, setOrganizationTopics] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [delTagId, setDelTagId] = useState([]);
  const [tableType, setTableType] = useState('');
  const [visibleTag, setVisibleTag] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [filteredMemberList, setFilteredMemberList] = useState([]);
  const [selectedContact, setSelectedContact] = useState({});
  const [memberDeleteModal, setMemberDeleteModal] = useState(false);
  const [importMemberVisibility, setImportMemberVisibility] = useState(false);
  const [CSVUploadStep, setCSVUploadStep] = useState(1);
  const [addMenuAnchor, setAddMenuAnchor] = useState(false);
  const [addMemberVisibility, setAddMemberVisibility] = useState(false);
  const [editMemberVisibility, setEditMemberVisibility] = useState(false);
  const [SelectedMemberNRIds, setSelectedMemberNRIds] = useState([]);
  const [ContactExportInfo, setContactExportInfo] = useState([]);
  const [quickSearchText, setQuickSearchText] = useState('');
  const [visibleColumns, setVisibleColumns] = useState(() => {
    return columnData.map(col => ({
      ...col,
      hide: !(props?.organization?.selected?.contactColumnsVisibility
        ? props.organization.selected.contactColumnsVisibility[col.field]
        : false),
    }));
  });

  const CSVUploadStepToTitleMap = {
    1: 'Upload Contacts from a File',
    2: 'Organizing Your New Contacts',
    3: 'Adding Data for New Contacts',
    4: 'Upload Results',
  };

  const { fullLoader } = useUpdateUIState();
  const { errorToast, successToast } = useToast();

  const reloadSelectedOrganizationInfo = async () => {
    await loadOrgInfo(props.organization.selected.id);
  };

  const handleGroupAssignmentModalClose = async ({ hasCreatedNewGroup }) => {
    if (hasCreatedNewGroup) {
      await reloadSelectedOrganizationInfo();
    }
  };

  const setColumnsVisibility = async () => {
    const { contactColumnsVisibility } = await getColumnsVisibility(
      props.organization.selected.id
    );

    const visibleColumns = columnData.map(col => ({
      ...col,
      hide: contactColumnsVisibility
        ? !contactColumnsVisibility[col.field]
        : false,
    }));

    setVisibleColumns(visibleColumns);
  };

  useEffect(() => {
    gAnalytics('pageview', '/manage');
    authUserHelper(props);
    setColumnsVisibility();
    fullLoader(true, 'Fetching Contacts ...');
    loadOrgInfo(props.organization.selected.id, true);

    if (!props.groupTypes.length) {
      getTagTypes().then(tagtypes => {
        if (tagtypes) props.actions.loadGroupTypes(tagtypes);
      });
    }

    window.scrollTo(0, 0);
  }, [props.organization.selected.id]);

  useEffect(() => {
    setColumnsVisibility();
  }, [props.organization.selected.contactColumnsVisibility]);

  const onActive = currentIndex => setIndex(currentIndex);

  const getMemberList = async orgId => {
    props.actions.fullloader(true, 'Fetching Contacts');
    const result = await getManageTableContacts(orgId);
    props.actions.fullloader(false);
    let contacts = [];

    if (result) {
      contacts = result.orgContacts;
    }

    if (!contacts?.error) {
      if (!contacts || contacts.length === 0) {
        setMemberList([]);
        setFilteredMemberList([]);
        return [];
      } else {
        const data = contacts.map(contact => {
          contact.id = contact.noteRouterId; //used for datagrid table
          contact.memberId = contact.source?.memberId || ''; //used for datagrid table
          contact.memberNRDSID =
            contact.organization?.attributes?.find(
              attr => attr.name === 'memberNRDSID'
            )?.value || ''; //used for datagrid table
          contact.id = contact.noteRouterId;
          contact.tags = contact.identifiers || [];
          return contact;
        });
        data.sort((a, b) => {
          if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
          if (b.firstName.toLowerCase() > a.firstName.toLowerCase()) return -1;
          return 0;
        });

        setMemberList(data);
        setFilteredMemberList(data);
        return data;
      }
    } else {
      setMemberList([]);
      setFilteredMemberList([]);
      errorToast('Error retrieving contacts.');
      return [];
    }
  };

  const getOrganizationTagsAndTopics = async (orgId, members, toast) => {
    fullLoader(true, 'Fetching Groups');
    const tagsInformation = await fetchOrgTopicsAndGroups(orgId);

    if (!tagsInformation.error) {
      let tagHolder = {};
      for (let tag of tagsInformation.tags) {
        tagHolder[tag.id] = tag.name;
      }
      for (let topic of tagsInformation.topics) {
        tagHolder[topic.id] = topic.name;
      }
      //Sort tags so that they are displayed alphabetically and by tag type
      tagsInformation.tags.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;
        return 0;
      });
      tagsInformation.tags.sort((a, b) => {
        if (a.groupTypeName > b.groupTypeName) return -1;
        if (b.groupTypeName > a.groupTypeName) return 1;
        return 0;
      });

      tagsInformation.topics.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;
        return 0;
      });

      updateMemberTagNames(members, tagHolder);
      setTagDetail(tagsInformation.tags);
      setTagList(tagsInformation.tags);
      setOrganizationTopics(tagsInformation.topics);

      props.actions.loadGroups(tagsInformation.tags);
      props.actions.loadTopics(tagsInformation.topics);
    } else {
      setTagDetail([]);
      setTagList([]);
      setOrganizationTopics([]);

      errorToast('Error retrieving groups.');
    }

    fullLoader(false);
    if (toast) toast();
  };

  const updateMemberTagNames = (members, tagNames) => {
    const updatedMembers = members.map(member => {
      try {
        return {
          ...member,
          tags: member.tags
            ? member.tags
                .filter(tagId => tagNames[tagId])
                .map(tagId => tagNames[tagId])
            : [],
        };
      } catch (e) {
        return {
          ...member,
          tags: [],
        };
      }
    });
    setMemberList(updatedMembers);
    setFilteredMemberList(updatedMembers);
  };

  const loadOrgInfo = async (orgId, first, toast) => {
    if (first) {
      fullLoader(true, 'Fetching Contacts ...');

      const members = await getMemberList(orgId);
      await getOrganizationTagsAndTopics(orgId, members, toast);
    } else {
      const members = await getMemberList(orgId);
      await getOrganizationTagsAndTopics(orgId, members, toast);
      if (quickSearchText) {
        requestSearch(quickSearchText);
      }
    }
  };

  const requestSearch = searchValue => {
    setQuickSearchText(searchValue);
    if (!searchValue) {
      setFilteredMemberList(memberList);
    } else {
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = memberList.filter(row => {
        const searchableData = {
          firstName: row.firstName || '',
          lastName: row.lastName || '',
          phone: row.phoneNumber || '',
          email: row.email || '',
          memberId: row.memberId || '',
          nrds: row.memberNRDSID || '',
          tags: JSON.stringify(row.tags) || '',
        };
        return Object.keys(searchableData).some(field => {
          return searchRegex.test(searchableData[field].toString());
        });
      });
      setFilteredMemberList(filteredRows);
    }
  };

  const handleFiles = async files => {
    let currentCount = 0;
    const reader = new FileReader();
    reader.onload = async () => {
      await Papa.parse(reader.result, {
        header: true,
        skipEmptyLines: 'greedy',
        transformHeader: header => {
          let formattedHeader = header.replace(/\s+/g, '').toLowerCase();
          if (formattedHeader === 'e-mail')
            formattedHeader = formattedHeader.replace('-', '');
          return formattedHeader;
        },
        complete: async results => {
          fullLoader(true, 'Adding contacts');
          let erroredImport = false;
          let members = [];
          for (let rep = 0; rep < results.data.length; rep++) {
            if (
              results.data[rep].firstname &&
              results.data[rep].firstname.length > 0 &&
              ((results.data[rep].email &&
                results.data[rep].email.length > 0) ||
                (results.data[rep].mobilenumber &&
                  results.data[rep].mobilenumber.length > 0))
            )
              members.push(results.data[rep]);
          }
          let chunkedMembers = [];
          for (let i = 0; i < members.length; i += 2500) {
            chunkedMembers = members.slice(i, i + 2500);
            currentCount += chunkedMembers.length;
            const csvOptions = {
              members: chunkedMembers,
              orgId: props.organization.selected.id,
            };
            fullLoader(
              true,
              `Adding contacts => (${currentCount} / ${members.length})`
            );
            const successImport = await csvImportMembers(csvOptions, results);

            if (successImport.error) {
              erroredImport = true;
              break;
            }
          }
          if (!erroredImport) {
            successToast('Imported your contact(s) successfully');
          } else {
            errorToast(
              'Error importing one or more contacts. This could be due to data missing required fields or having duplicate emails.'
            );
          }
          setImportMemberVisibility(false);
          fullLoader(false);
          await loadOrgInfo(props.organization.selected.id);
        },
      });
    };
    reader.readAsText(files[0]);
  };

  const showEditMemberDrawer = async data => {
    setEditMemberVisibility(true);
    setSelectedContact({});

    if (props.organization.selected.id) {
      setTimeout(async () => {
        data.orgId = props.organization.selected.id;

        const memberData = await getContactById(data);

        if (!memberData?.error) {
          setEditMemberVisibility(true);
          setSelectedContact(memberData[0]);
        } else errorToast("Error getting contact's information");
      }, 500);
    }
  };

  const closeEditMemberDrawer = () => {
    setEditMemberVisibility(false);
    setSelectedContact({});
  };

  const showMemberImportDrawer = () => {
    if (props.organization.selected.id) setImportMemberVisibility(true);
    else {
      const checkdata = {
        type: 'fail',
        message: 'Organization Id not selected',
      };

      props.actions.checkOrganizationId(checkdata);
    }
  };

  const deleteTag = async () => {
    let successText = `Deleted ${modifyWord(tableType)} successfully.`;

    fullLoader(true, `Deleting ${modifyWord(tableType)}`);
    const deleteOptions = {
      orgId: props.organization.selected.id,
      ids: delTagId,
      type: tableType,
    };

    const deleted = await deleteSelectedGroups(deleteOptions);

    setVisibleTag(false);
    setDelTagId([]);

    fullLoader(false);

    if (deleted.scheduledMsgStandbyHasDeletedTags) {
      successText +=
        '\n There are Pending or Scheduled messages that are using this Topic/Group. These messages will still be sent successfully for this message only utilizing that Topic/Group, even after the Topic/Group is deleted.';
    }

    if (!deleted.error) {
      const toastCallback = () => successToast(successText);

      await loadOrgInfo(props.organization.selected.id, true, toastCallback);
    } else {
      errorToast(
        `Something went wrong deleting your ${modifyWord(tableType)}.`
      );
    }
  };

  const deleteMember = async () => {
    const toastCallback = () => successToast('Deleted contact(s) successfully');
    fullLoader(true, 'Deleting contact(s)');
    let ids = SelectedMemberNRIds;

    let tagCounts = {};
    let topicCounts = {};
    let emailTopicCounts = {};
    let textTopicCounts = {};

    let membersToDelete = memberList.filter(mem =>
      ids.includes(mem.noteRouterId)
    );

    for (let member of membersToDelete) {
      for (let tag of member.identifiers) {
        if (!tagCounts[tag]) tagCounts[tag] = -1;
        else tagCounts[tag]--;
      }

      let combinedTopics = isUnion(member.emailTags, member.textTags);
      for (let topic of combinedTopics) {
        if (!topicCounts[topic]) topicCounts[topic] = -1;
        else topicCounts[topic]--;
      }
      for (let topic of member.emailTags) {
        if (!emailTopicCounts[topic]) emailTopicCounts[topic] = -1;
        else emailTopicCounts[topic]--;
      }
      for (let topic of member.textTags) {
        if (!textTopicCounts[topic]) textTopicCounts[topic] = -1;
        else textTopicCounts[topic]--;
      }
    }

    const topicSubscriberUpdateCounts = {
      totalTopicCounts: topicCounts,
      emailTopicCounts,
      textTopicCounts,
    };
    const tagSubscriberUpdateCounts = {
      tagCounts,
    };

    setMemberDeleteModal(false);
    setSelectedMemberNRIds([]);

    const deleted = await deleteContactsFromOrg({
      ids,
      orgId: props.organization.selected.id,
    });
    if (!deleted.error) {
      await updateTopicsSubscriberCounts(topicSubscriberUpdateCounts);
      await updateTagsSubscriberCounts(tagSubscriberUpdateCounts);
      setTimeout(async () => {
        fullLoader(false);
        await loadOrgInfo(props.organization.selected.id, true, toastCallback);
      }, 2000);
    } else {
      fullLoader(false);
      errorToast('Something went wrong deleting your contact(s).');
    }
  };

  const getSelectedMembers = async () => {
    const orgNotes = await getAllOrgNotes(props.organization.selected.id);
    const exportInfo = [];
    const exportAll =
      SelectedMemberNRIds.length === memberList.length ||
      !SelectedMemberNRIds.length;
    memberList.forEach(contactData => {
      const contactNotes = orgNotes[contactData.noteRouterId];
      let selected = exportAll
        ? true
        : SelectedMemberNRIds.includes(contactData.noteRouterId);
      if (contactData && selected) {
        let NRDS = '';
        let licenseExpDate = '';
        if (contactData.organization && contactData.organization.attributes) {
          NRDS = contactData.organization.attributes.find(
            attr => attr.name === 'memberNRDSID'
          );
          licenseExpDate = contactData.organization.attributes.find(
            attr => attr.name === 'memberStateLicenseExpirationDate'
          );
        }

        const formatExportDate = date => {
          if (!date) return '';
          if (date.length === 10)
            return (
              date.substring(5, 7) +
              '/' +
              date.substring(8) +
              '/' +
              date.substring(2, 4)
            );
        };

        const contactSourceName = ['csv', 'nr'].includes(
          contactData.source.name
        )
          ? contactData.source.name.toUpperCase()
          : 'AMS';

        exportInfo.push({
          'First Name': contactData.firstName || '',
          'Last Name': contactData.lastName || '',
          Email: contactData.email || '',
          'Phone Number': contactData.phoneNumber || '',
          'Member #':
            contactData.source && contactData.source.memberId
              ? contactData.source.memberId
              : '',
          'NRDS #': NRDS ? NRDS.value : '',
          'License Expiration': formatExportDate(licenseExpDate?.value),
          Birthdate: formatExportDate(contactData.birthdate),
          'Topic IDs':
            isUnion(contactData.emailTags, contactData.textTags) || '',
          'Group IDs': contactData.identifiers || '',
          'Member/Nonmember': contactData.isMember ? 'Member' : 'Nonmember',
          Source: contactSourceName,
          'Added to NoteRouter Date': moment
            .unix(contactData.createdDate)
            .format('M/D/YY h:mm A'),
          'Last Updated Date': contactData.updatedDate
            ? moment.unix(contactData.updatedDate).format('M/D/YY h:mm A')
            : 'N/A',
          Notes: contactNotes,
        });
      }
    });
    setContactExportInfo(exportInfo);
    fullLoader(false);
  };

  useEffect(() => {
    if (ContactExportInfo?.length) csvLink.current.link.click();
  }, [ContactExportInfo]);

  const getThemePaletteMode = palette => {
    return palette.type || palette.mode;
  };
  const defaultTheme = createTheme();
  const useStyles = makeStyles(
    theme => {
      const getBackgroundColor = color =>
        getThemePaletteMode(theme.palette) === 'dark'
          ? darken(color, 0.6)
          : lighten(color, 0.6);

      const getHoverBackgroundColor = color =>
        getThemePaletteMode(theme.palette) === 'dark'
          ? darken(color, 0.5)
          : lighten(color, 0.5);

      return {
        root: {
          '& .super-app-theme--alert': {
            backgroundColor: getBackgroundColor(theme.palette.error.main),
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: getHoverBackgroundColor(
                theme.palette.error.main
              ),
            },
          },
          '& .super-app-theme--general': {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      };
    },
    { defaultTheme }
  );
  const classes = useStyles();

  const addMenu = (
    <>
      <Button
        id="add-member-menu-button"
        aria-controls="add-menu"
        aria-haspopup="true"
        aria-expanded={addMenuAnchor ? 'true' : undefined}
        size={'small'}
        variant="outlined"
        startIcon={<AddCircleIcon />}
        onClick={event => setAddMenuAnchor(event.currentTarget)}
        style={{ background: '#5EAB00', color: '#fff', borderColor: '#5EAB00' }}
      >
        Add
      </Button>
      <Menu
        className={'addMenuContainer'}
        id="add-menu"
        aria-labelledby="add-member-menu-button"
        anchorEl={addMenuAnchor}
        getContentAnchorEl={null} //Need this to have the menu open up in the correct spot with anchorOrigin and transformOrigin
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(addMenuAnchor)}
        onClose={() => setAddMenuAnchor(null)}
      >
        <MenuItem
          onClick={() => {
            setAddMenuAnchor(null);
            setAddMemberVisibility(true);
          }}
        >
          Add a Contact
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAddMenuAnchor(null);
            showMemberImportDrawer();
          }}
        >
          Upload from File
        </MenuItem>
      </Menu>
    </>
  );

  const onColumnVisibilityChange = async ({ field, isVisible }) => {
    if (props.user.userPrivileges.isSU && field !== '__check__') {
      await updateOrganization({
        id: props.organization.selected.id,
        [`contactColumnsVisibility.${field}`]: isVisible,
      });
      const orgData = {
        ...props.organization.selected,
        contactColumnsVisibility: {
          ...props.organization.selected.contactColumnsVisibility,
          [field]: isVisible,
        },
      };
      props.actions.userOrganization(orgData);
    }
  };

  return (
    <Grommet className="ManageContainer">
      <Box className="ManageGrommetBox">
        <Tabs className="topTabs" activeIndex={index} onActive={onActive} flex>
          {/* MEMBER TAB secion start */}
          <Tab title="Contacts" className="membersTab">
            <Box
              overflow-x="hidden"
              overflow-y="auto"
              align="center"
              style={{ height: '100%' }}
            >
              <Container>
                <Wrapper>
                  {/*AMS Sync Dropdown*/}
                  <div className={'AMSSyncRow'}>
                    {props.user.userPrivileges.isSU &&
                      props.organization.selected.importMagic && (
                        <AMSSync
                          orgDetails={props.organization.selected}
                          userId={props.user.detail.id}
                          refreshManageScreen={orgId =>
                            loadOrgInfo(orgId, true)
                          }
                        />
                      )}
                  </div>
                  <div className={'AMSSyncRow'}>
                    {props.user.userPrivileges.isSU && (
                      <AMSSyncAll
                        orgDetails={props.organization.selected}
                        userId={props.user.detail.id}
                        refreshManageScreen={orgId => loadOrgInfo(orgId, true)}
                      />
                    )}
                  </div>
                </Wrapper>
                <RightTopContainer>
                  {props.user.userPrivileges.manageMembers && (
                    <span>
                      <BulkGroupAssignmentButton
                        className="bulkGroupAssignmentButton"
                        selectedMemberIds={SelectedMemberNRIds}
                        onAssignGroups={reloadSelectedOrganizationInfo}
                        onClose={handleGroupAssignmentModalClose}
                      />
                      <Button
                        style={{
                          color: '#6C7075',
                          background: '#fff',
                          border: '1px solid #D4D7D9',
                          marginRight: '15px',
                        }}
                        onClick={() => {
                          fullLoader(
                            true,
                            'Exporting your CSV, this may take a moment.'
                          );
                          getSelectedMembers();
                        }}
                        size={'small'}
                        variant="outlined"
                        startIcon={<CloudDownloadIcon />}
                      >
                        Export
                      </Button>
                      <CSVLink
                        data={ContactExportInfo}
                        filename={`Contact Data Export - ${moment().format(
                          'MM.DD.YYYY hh.mm A'
                        )}.csv`}
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                      />
                    </span>
                  )}
                  {props.user.userPrivileges.manageMembers && (
                    <Button
                      size={'small'}
                      variant="contained"
                      startIcon={<DeleteIcon />}
                      onClick={() => setMemberDeleteModal(true)}
                      disabled={SelectedMemberNRIds.length <= 0}
                      style={{
                        background:
                          SelectedMemberNRIds?.length > 0
                            ? '#FF6161'
                            : '#CACACA',
                        marginRight: '16px',
                        color: SelectedMemberNRIds?.length > 0 ? '#fff' : '',
                      }}
                    >
                      Delete
                    </Button>
                  )}
                  {props.user.userPrivileges.manageMembers && addMenu}
                  <ChildDrawer
                    id="newContactDrawer"
                    anchor={'right'}
                    onClose={() => setAddMemberVisibility(false)}
                    open={addMemberVisibility}
                    Component={
                      <ManageAddForm
                        drawerTitle="Add New Contact"
                        parentId="newContactDrawer"
                        manageTags={tagList}
                        organisation={props.organization.selected.id}
                        isLoading={false}
                        update={id => {
                          loadOrgInfo(id);
                        }}
                        closeDrawer={() => setAddMemberVisibility(false)}
                      />
                    }
                  />
                </RightTopContainer>
              </Container>
              <div className="ManageTableContainer">
                <Box style={{ height: '100%' }}>
                  <span className={'ManageDataGrid'}>
                    <DataGrid
                      className={classes.root}
                      stickyHeader
                      rows={filteredMemberList}
                      columns={visibleColumns}
                      sortingOrder={['asc', 'desc']}
                      // disableColumnMenu /* 4/5/23 -- re-enable this for now as it seems to be useful for some use cases */
                      getRowClassName={params => {
                        const alert =
                          params.getValue(params.id, 'bounceAlert') ||
                          params.getValue(params.id, 'smsAlert') ||
                          params.getValue(params.id, 'spamAlert');
                        return alert
                          ? 'super-app-theme--alert'
                          : 'super-app-theme--general';
                      }}
                      pageSize={pageSize}
                      onPageSizeChange={size => setPageSize(size)}
                      checkboxSelection
                      disableSelectionOnClick
                      onRowClick={data => {
                        showEditMemberDrawer(data.row);
                      }}
                      onSelectionModelChange={newSelectionModel => {
                        setSelectedMemberNRIds(newSelectionModel);
                      }}
                      selectionModel={SelectedMemberNRIds}
                      components={{ Toolbar: SearchBar }}
                      componentsProps={{
                        toolbar: {
                          value: quickSearchText,
                          onChange: event => requestSearch(event.target.value),
                          clearSearch: () => requestSearch(''),
                          isColumnButtonVisible: props.user.userPrivileges.isSU,
                        },
                      }}
                      onColumnVisibilityChange={onColumnVisibilityChange}
                      disableColumnSelector={!props.user.userPrivileges.isSU}
                      localeText={{
                        columnMenuSortAsc: 'Sort ascending',
                        columnMenuSortDesc: 'Sort descending',
                      }}
                    />
                  </span>
                  {selectedContact && (
                    <ChildDrawer
                      id="ContactDetailDrawer"
                      anchor="right"
                      onClose={closeEditMemberDrawer}
                      open={editMemberVisibility}
                      Component={
                        <ManageAddForm
                          parentId="ContactDetailDrawer"
                          drawerTitle={`Details: ${
                            selectedContact?.firstName ?? ''
                          } ${selectedContact?.lastName ?? ''}`}
                          tags={tagList}
                          data={selectedContact}
                          isLoading={!selectedContact?.noteRouterId}
                          update={async id => {
                            await loadOrgInfo(id);
                          }}
                          updateDrawer={async contactInfo => {
                            await showEditMemberDrawer(contactInfo);
                          }}
                          closeDrawer={closeEditMemberDrawer}
                        />
                      }
                    />
                  )}
                </Box>
              </div>
            </Box>
          </Tab>
          <Tab title="Topics" className="membersTab">
            <TagsTable
              delTagId={delTagId}
              tagDetail={organizationTopics}
              pageSize={pageSize}
              showModalTag={() => setVisibleTag(true)}
              organizationtaglist={id =>
                getOrganizationTagsAndTopics(id, memberList)
              }
              loadOrgInfo={id => loadOrgInfo(id)}
              setTagIDs={ids => {
                setDelTagId(ids);
                setTableType('topics');
              }}
              tableType={'topics'}
              memberList={memberList}
            />
          </Tab>
          <Tab title="Groups" className="membersTab">
            <TagsTable
              delTagId={delTagId}
              tagDetail={tagDetail}
              pageSize={pageSize}
              showModalTag={() => setVisibleTag(true)}
              organizationtaglist={id =>
                getOrganizationTagsAndTopics(id, memberList)
              }
              loadOrgInfo={id => loadOrgInfo(id)}
              setTagIDs={ids => {
                setDelTagId(ids);
                setTableType('groups');
              }}
              tableType={'groups'}
              memberList={memberList}
            />
          </Tab>
          {(index === 0 || index === 4) && (
            <span style={{ fontSize: '20px', margin: '6px 20px 10px 8px' }}>
              |
            </span>
          )}
          {(index === 0 || index === 4) && (
            <Tab
              title="Fields & Data"
              className="membersTab FieldsAndData"
              style={{ position: 'relative', top: '39px' }}
            >
              <FieldManager
                contacts={memberList}
                refreshAll={() => loadOrgInfo(props.organization.selected.id)}
              />
            </Tab>
          )}
        </Tabs>
      </Box>

      <Drawer
        anchor={'right'}
        onClose={() => {
          setImportMemberVisibility(false);
        }}
        open={importMemberVisibility}
        className={'UploadContactDrawer'}
      >
        <CSVUploadContact
          closeDrawer={() => {
            setImportMemberVisibility(false);
            if (CSVUploadStep === 4) {
              loadOrgInfo(props.organization.selected.id);
            }
          }}
          setCSVUploadStep={setCSVUploadStep}
          CSVUploadStep={CSVUploadStep}
          drawerTitle={
            <span style={{ paddingLeft: '10px' }}>
              {CSVUploadStepToTitleMap[CSVUploadStep]}
            </span>
          }
          closeAndRefresh={() => {
            setImportMemberVisibility(false);
            loadOrgInfo(props.organization.selected.id);
          }}
        />
      </Drawer>

      <Drawer
        title="Import Contacts from .CSV"
        width={360}
        onClose={() => setImportMemberVisibility(false)}
        visible={importMemberVisibility}
        style={{
          overflow: 'auto',
        }}
      >
        <Form hideRequiredMark>
          <p className="addmember">Helpful Info</p>
          <div className="downloadanchor">
            <Form.Item>
              Guide:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#1890ff' }}
                href="https://noterouter.freshdesk.com/en/support/solutions/articles/69000277002-how-to-uploading-members-and-nonmembers-"
              >
                Importing Members from .CSV
              </a>
            </Form.Item>
          </div>

          <p className="addmember">Get Sample Upload File</p>
          <div className="downloadanchor">
            <Form.Item>
              <a
                style={{ color: '#1890ff' }}
                href="https://firebasestorage.googleapis.com/v0/b/noterouter-staging.appspot.com/o/sample%2FSample_Member_CSV.csv?alt=media&token=628ab2ad-6bba-4881-a7a0-f62bc32da66b"
              >
                Download
              </a>
            </Form.Item>
          </div>

          <p className="addmember spacehead">Upload</p>
          <Form.Item>
            <div className="row">
              <ReactFileReader
                handleFiles={handleFiles}
                fileTypes={['.csv', '.xlsx']}
              >
                <button className="chooseFileBtn">Choose File</button>
              </ReactFileReader>
            </div>
          </Form.Item>
        </Form>
      </Drawer>

      {memberDeleteModal && (
        <DeleteMemberModal
          deleteMember={() => deleteMember()}
          hideMemberDelModal={() => setMemberDeleteModal(false)}
          numberOfUsers={SelectedMemberNRIds.length}
        />
      )}

      {visibleTag && (
        <DeleteTagModal
          setVisible={setVisibleTag}
          tagList={tagList}
          deleteList={delTagId}
          organizationTopics={organizationTopics}
          deleteTag={() => deleteTag()}
        />
      )}
    </Grommet>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actioncreators, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Manage));
