import { _pick } from 'underscore-es';
import api from '../../utils/andromeda-api';

const CORE_AUDIENCE_DATA_FIELDS = [
  'topicIds',
  'selectedGroupIds',
  'requiredGroupIds',
  'excludedGroupIds',
  'includedMemberIds',
  'excludedMemberIds',
  'filterData',
];

const STANDALONE_AUDIENCE_DATA_FIELDS = [
  'name',
  'description',
  ...CORE_AUDIENCE_DATA_FIELDS,
];

export const getCoreAudienceData = audience => {
  return _pick(audience, CORE_AUDIENCE_DATA_FIELDS);
};

export const getStandaloneAudienceData = audience => {
  return _pick(audience, STANDALONE_AUDIENCE_DATA_FIELDS);
};

export async function getOrgAudiences({
  orgId,
  skip,
  limit,
  sort,
  filter,
  searchValue,
}) {
  const { data } = await api.get(`/audiences/organization/${orgId}`, {
    params: { skip, limit, sort, filter, searchValue },
  });

  return data;
}

export async function getAudience(id) {
  const { data } = await api.get(`/audiences/${id}`);
  return data.audience;
}

export async function saveAudience(audience) {
  const { data } = await api.post('/audiences', audience);
  return data.audience;
}

export async function updateAudience(update) {
  const response = await api.put(`/audiences/${update._id}`, update);

  return response.data;
}
