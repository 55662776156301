import { Col, Drawer, Row, Spin } from 'antd';
import { Box } from 'grommet';
import MaterialTable, { MTableCell } from 'material-table';
import * as moment from 'moment';
import React, { useState } from 'react';

import {
  getContactByIdPaginated,
  getMemberEventInfo,
} from '../../redux/services';

const ContactsTable = props => {
  moment.locale('en');
  const [pageSize, setPageSize] = useState(50);
  const [detailsDrawer, setDetailsDrawer] = useState(false);
  const [orgId] = useState(props.orgId);
  const [msgId] = useState(props.contactData.id);
  const [memsLoading, setMemsLoading] = useState(false);
  const [memberInfo] = useState({});
  const [, setMessageInfo] = useState({});
  const [msgDetailsLoading, setMsgDetailsLoading] = useState(false);
  const [memberMessageInfo, setMemberMessageInfo] = useState({});
  const [memberEngagement, setMemberEngagement] = useState({ events: [] });

  const showdetailsDrawer = async data => {
    setDetailsDrawer(true);
    setMessageInfo(data);
    setMemberEngagement({ events: [] });
    setMsgDetailsLoading(true);
    const info = await getMemberEventInfo(msgId, data.noteRouterId, orgId);
    if (info) {
      data.memberEmail = info.messageInfo.memberEmail;
      data.memberPhone = info.messageInfo.memberPhone;
      data.deliveryDate =
        typeof info.messageInfo.deliveryDate === 'number'
          ? moment
              .unix(info.messageInfo.deliveryDate)
              .format('MM/DD/YY hh:mm A')
          : info.messageInfo.deliveryDate;
      data.orgPhone = info.messageInfo.orgPhone;
      data.from = info.messageInfo.from;

      setMessageInfo(data);
      setMemberMessageInfo(data);
      setMemberEngagement(info.memberEngagement);
      setMsgDetailsLoading(false);
    } else {
      setMessageInfo(data);
      setMemberMessageInfo(data);
      setMemberEngagement({});
      setMsgDetailsLoading(false);
    }
  };

  const onDetailsDrawerClose = () => {
    setDetailsDrawer(false);
  };

  const messageStatus = type => {
    const isEmailType = type === 'email';
    const messageType = isEmailType
      ? memberEngagement.recievedByEmail
      : memberEngagement.recievedByText;
    const messageError = isEmailType
      ? memberEngagement.emailError
      : memberEngagement.textError;

    if (messageType) {
      return (
        <span className="detailscontent" style={{ color: '#06c306' }}>
          Delivered
        </span>
      );
    } else if (messageError) {
      return (
        <span
          className="detailscontent"
          style={{ color: 'rgba(255, 0, 0, 0.7490196078431373)' }}
        >
          Not Delivered
        </span>
      );
    } else {
      return (
        <span className="detailscontent" style={{ color: 'rgb(0,0,0)' }}>
          None Sent
        </span>
      );
    }
  };

  const clickCountStyle = () => {
    if (memberEngagement.clickCount > 0) {
      return (
        <span className="detailscontent" style={{ color: '#06c306' }}>
          {memberEngagement.clickCount}
        </span>
      );
    } else {
      return (
        <span className="detailscontent" style={{ color: 'rgb(0,0,0)' }}>
          {memberEngagement.clickCount}
        </span>
      );
    }
  };

  const openCountStyle = () => {
    if (memberEngagement.openCount > 0) {
      return (
        <span style={{ color: '#06c306' }}>{memberEngagement.openCount}</span>
      );
    } else {
      return (
        <span style={{ color: 'rgb(0,0,0)' }}>
          {memberEngagement.openCount}
        </span>
      );
    }
  };

  const columnsData = [
    {
      title: 'First Name',
      field: 'firstName',
      defaultSort: 'asc',
      // eslint-disable-next-line react/display-name
      render: rowData => {
        return <p>{rowData.firstName}</p>;
      },
    },
    {
      title: 'Last Name',
      field: 'lastName',
      // eslint-disable-next-line react/display-name
      render: rowData => {
        return <p>{rowData.lastName}</p>;
      },
    },
    {
      title: 'Email',
      field: 'email',
      // eslint-disable-next-line react/display-name
      render: rowData => {
        return <p>{rowData.email}</p>;
      },
    },
  ];

  return (
    <div className="ManagePortalUserscomp ant-drawerpart">
      <Box overflow="auto">
        <div className="middlePart">
          <Box className="noterouter_managetable btnseparation">
            <Spin spinning={memsLoading} tip="Loading Contacts Sent to...">
              <MaterialTable
                components={{
                  // eslint-disable-next-line react/display-name
                  Cell: props => <MTableCell {...props} />,
                }}
                columns={columnsData}
                data={query => {
                  setMemsLoading(true);
                  return new Promise(resolve => {
                    getContactByIdPaginated({
                      orgId,
                      page: query.page + 1,
                      size: query.pageSize,
                      search: query.search,
                      messageId: msgId,
                    }).then(({ result, pagination }) => {
                      setMemsLoading(false);
                      resolve({
                        data: result,
                        page: pagination.page - 1,
                        totalCount: pagination.total,
                      });
                    });
                  });
                }}
                className="Manage_materialtable"
                title=""
                actions={[]}
                options={{
                  search: true,
                  sorting: true,
                  pageSize: pageSize,
                  pageSizeOptions: [10, 20, 50],
                  debounceInterval: 500,
                }}
                onChangeRowsPerPage={e => {
                  localStorage.setItem('pageSize', e);
                  setPageSize(e);
                }}
                onRowClick={(e, data) => showdetailsDrawer(data)}
                onPageChange={_e => null}
              />
            </Spin>

            <Drawer
              title="Engagement Details"
              className="detailsSection"
              width={475}
              onClose={onDetailsDrawerClose}
              visible={detailsDrawer}
            >
              {memberMessageInfo && memberInfo && (
                <Spin
                  spinning={msgDetailsLoading}
                  tip="Loading Message Details..."
                >
                  <div>
                    <Row>
                      <p className="detailspart">
                        <span className="detailslabel">Delivered Date: </span>
                        <span className="detailscontent">
                          {memberMessageInfo.deliveryDate}
                        </span>
                      </p>
                    </Row>
                    <Row>
                      <p className="detailspart">
                        <span className="detailslabel">Sent From: </span>
                        <span className="detailscontent">
                          {memberMessageInfo.from}
                          <span>
                            {'  '}&#9679;{'  '}
                          </span>
                          {memberMessageInfo.orgPhone}
                        </span>
                      </p>
                    </Row>
                    <Row>
                      <p className="detailspart">
                        <span className="detailslabel">Receiving Email: </span>
                        <span className="detailscontent">
                          {memberMessageInfo.memberEmail}
                        </span>
                      </p>
                    </Row>
                    <Row>
                      <p className="detailspart">
                        <span className="detailslabel">
                          Receiving Phone Number:{' '}
                        </span>
                        <span className="detailscontent">
                          {memberMessageInfo.memberPhone}
                        </span>
                      </p>
                    </Row>
                    <Row>
                      <p className="detailspart">
                        <span className="detailslabel">
                          <a
                            href={`${window.location.origin}/message?id=${memberMessageInfo.id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View Message
                          </a>
                        </span>
                        <span className="detailscontent" />
                      </p>
                    </Row>

                    <Col span={24} className="tagsec">
                      <div className="labelHead">
                        <span className="detailslabel taghead">
                          <b>{'Message Status:'}</b>
                        </span>
                      </div>
                      <p className="boxDetails">
                        <span
                          className="detailscontent"
                          style={{ color: 'rgb(0,0,0)' }}
                        >
                          Email:{' '}
                        </span>
                        {messageStatus('email')}
                        <span className="textpart">
                          <span
                            className="detailscontent"
                            style={{ color: 'rgb(0,0,0)' }}
                          >
                            Text :{' '}
                          </span>
                        </span>
                        {messageStatus('text')}
                      </p>
                    </Col>

                    <Col span={24} className="tagsec">
                      <div className="labelHead">
                        <span className="detailslabel taghead">
                          <b>{'Engagement:'}</b>
                        </span>
                      </div>
                      <p className="boxDetails">
                        <span style={{ color: 'rgb(0,0,0)' }}>
                          <span className="detailslabel">
                            <span
                              className="detailscontent"
                              style={{ color: 'rgb(0,0,0)' }}
                            >
                              Opens:{' '}
                            </span>
                          </span>
                          {openCountStyle()}
                        </span>

                        <span
                          className="textClick"
                          style={{ color: 'rgb(0,0,0)' }}
                        >
                          <span className="detailslabel">
                            <span
                              className="detailscontent"
                              style={{ color: 'rgb(0,0,0)' }}
                            >
                              Clicks:{' '}
                            </span>
                          </span>
                          {clickCountStyle()}
                        </span>
                      </p>
                    </Col>

                    <Col span={24} className="tagsec">
                      <div className="labelHead">
                        <span className="detailslabel taghead">
                          <b>{'Events:'}</b>
                        </span>
                      </div>
                      {memberEngagement?.events?.map((event, i) => {
                        return (
                          <li key={i}>
                            <span style={{ color: 'rgb(0,0,0)' }}>
                              {`${event.eventType} ${event.eventName
                                .charAt(0)
                                .toUpperCase()}${event.eventName.substring(
                                1
                              )} - ${moment
                                .unix(event.time)
                                .format('MM/DD/YY    hh:mm A')}`}
                            </span>
                          </li>
                        );
                      })}
                    </Col>
                  </div>
                </Spin>
              )}
            </Drawer>
          </Box>
        </div>
      </Box>
    </div>
  );
};

export default ContactsTable;
