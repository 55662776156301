import React from 'react';
import { Select } from 'antd';

const SortCardsSelectDropdown = ({
  sortBy,
  activeSortByValue,
  setSortByValue,
}) => {
  const Option = Select.Option;
  return (
    <Select
      value={activeSortByValue}
      onSelect={value => setSortByValue(value)}
      style={{
        width: '100px',
        textAlign: 'left',
        border: '1px solid #d9d9d9',
        borderRadius: '5px',
        backgroundColor: 'white',
      }}
      bordered={false}
      size="large"
    >
      {sortBy.map(({ name, value }) => (
        <Option key={name} value={value}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

export default SortCardsSelectDropdown;
