import 'antd/dist/antd.css';
import '../../globalcss.css';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCustomFields,
  getSingleContactCustomFieldData,
  updateContactCustomFields,
  updateContactById,
} from '../../redux/services';
import { selectedOrganizationSelector } from '../../redux/selectors/organization';
import { DataGrid } from '@mui/x-data-grid';
import { userPrivilegesSelector } from '../../redux/selectors/user';

const ContactCustomFields = props => {
  const [ContactCurrentCustomFields, setContactCurrentCustomFields] =
    useState(null); //Array of the individual contacts CUSTOM fields
  const [OrgFields, setOrgFields] = useState([]); //The organizations list of all fields.
  const [ContactFieldUpdates, setContactFieldUpdates] = useState({}); //Object that contains all updates to a contacts fields. {fieldId: newValue}
  const [APIContact, setAPIContact] = useState(false); //Boolean that describes whether the contact has information sourced from an AMS.
  const [ContactHasCustomData, setContactHasCustomData] = useState(true); //Boolean that describes whether or not the contact has existing custom fields.
  const [isLoading, setIsLoading] = useState({ value: false, text: '' });
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const [tableData, setTableData] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const userPrivileges = useSelector(userPrivilegesSelector);

  useEffect(() => {
    if (props.contactDetails.noteRouterId && selectedOrganization.id)
      grabContactCustomFields();
  }, [props.contactDetails.noteRouterId]);

  const grabContactCustomFields = async () => {
    setIsLoading({ value: true, text: 'Loading contact fields' });
    let orgFields = await getCustomFields(
      selectedOrganization.id,
      selectedOrganization.orgType
    );
    orgFields.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    setOrgFields(orgFields);
    const contactCustomFields = await getSingleContactCustomFieldData(
      //Fetches any custom field data the contact may have.
      props.contactDetails.noteRouterId,
      selectedOrganization.id
    );
    setContactCurrentCustomFields(
      contactCustomFields?.customData ? contactCustomFields.customData : []
    );
    if (!contactCustomFields) setContactHasCustomData(false);

    if (props.contactDetails.source) {
      //Disable certain input fields if the member/contact is imported from an AMS.
      const platform =
        props.contactDetails?.source?.name?.toLowerCase() || 'nr';
      if (!['nr', 'csv'].includes(platform)) setAPIContact(true);
    }

    setIsLoading({ value: false });
    formatTableData(
      orgFields,
      contactCustomFields?.customData ? contactCustomFields.customData : []
    );
  };

  const formatTableData = (organizationCustomFields, contactCustomFields) => {
    const formattedData = organizationCustomFields.map(customField => {
      let fieldValue;
      let fieldModifiedDate;

      //Handle custom fields first
      if (customField.category === 'Custom') {
        const contactFieldDetails = contactCustomFields.find(
          field => field.id === customField.id
        );
        fieldValue = contactFieldDetails?.value
          ? contactFieldDetails.value
          : '';
        fieldModifiedDate = contactFieldDetails?.lastUpdated
          ? moment.unix(contactFieldDetails.lastUpdated).format('M/D/YYYY')
          : '';

        return {
          name: customField.name,
          value: fieldValue,
          lastModified: fieldModifiedDate,
          id: customField.id,
          type: customField.category,
        };
      }

      //If the field is not a custom field, grab the contacts last updated value
      if (typeof props.contactDetails.updatedDate === 'number') {
        fieldModifiedDate = moment
          .unix(props.contactDetails.updatedDate)
          .format('M/D/YYYY');
      } else if (typeof props.contactDetails.updatedDate === 'string') {
        fieldModifiedDate = moment(props.contactDetails.updatedDate).format(
          'M/D/YYYY'
        );
      } else {
        fieldModifiedDate = '';
      }

      if (customField.fieldName === 'coeComplete') {
        const coeVal = props.contactDetails[customField.fieldName];
        if (coeVal === true) fieldValue = 'Complete';
        else if (coeVal === false) fieldValue = 'Not Complete';
        else fieldValue = 'N/A';
      } else if (
        //If the field is on the contact at a top level
        props.contactDetails[customField.fieldName]
      ) {
        fieldValue = props.contactDetails[customField.fieldName];
        if (
          customField.fieldName === 'lastPreferencePageVisited' &&
          fieldValue
        ) {
          fieldValue = moment.unix(fieldValue).format('M/D/YYYY');
        }
      } else if (customField.category === 'Integrated') {
        if (
          props.contactDetails.organization?.attributes &&
          customField.fieldName !== 'memberId'
        ) {
          const attributeVal =
            props.contactDetails.organization?.attributes.find(
              attr => attr.name === customField.fieldName
            );
          fieldValue = attributeVal ? attributeVal.value : '';
        } else if (customField.fieldName === 'memberId') {
          fieldValue = props.contactDetails.source?.memberId
            ? props.contactDetails.source.memberId
            : '';
        }
      }
      return {
        name: customField.name,
        value: fieldValue,
        lastModified: fieldModifiedDate,
        id: customField.id,
        type: customField.category,
      };
    });
    setTableData(formattedData);
  };

  const editCustomFieldValue = ({ value, id }) => {
    setContactFieldUpdates({
      ...ContactFieldUpdates,
      [id]: value,
    });
  };

  const saveFieldModifications = async () => {
    if (!Object.keys(ContactFieldUpdates).length) {
      props.closeDrawer();
      return;
    }
    setIsLoading({ value: true, text: 'Saving contact field changes' });
    //Split into 2 updates. Custom and basic.
    //Custom just update the array but basic has to be manually found and updated
    const customFieldDetails = {};
    const basicFieldDetails = {};
    const integratedFieldDetails = [];
    const updateTracking = {
      custom: false,
      basic: false,
      integrated: false,
    };
    for (let field of ContactCurrentCustomFields) {
      customFieldDetails[field.id] = field;
    }
    for (let fieldId in ContactFieldUpdates) {
      const orgFieldData = OrgFields.find(
        fieldInfo => fieldInfo.id === fieldId
      );
      if (orgFieldData.category === 'Custom') {
        updateTracking.custom = true;
        if (customFieldDetails[fieldId]) {
          //If there is already an existing value, just replace it.
          customFieldDetails[fieldId].value = ContactFieldUpdates[fieldId];
        } else {
          //Otherwise just add it
          customFieldDetails[fieldId] = {
            value: ContactFieldUpdates[fieldId],
            id: fieldId,
            lastUpdated: moment().unix(),
          };
        }
      } else if (orgFieldData.category === 'Basic') {
        updateTracking.basic = true;
        basicFieldDetails[orgFieldData.fieldName] =
          ContactFieldUpdates[fieldId];
      } else {
        if (orgFieldData.fieldName === 'memberId') {
          updateTracking.basic = true;
          basicFieldDetails['source.memberId'] = ContactFieldUpdates[fieldId];
        } else if (orgFieldData.category === 'Integrated') {
          updateTracking.integrated = true;
          integratedFieldDetails.push({
            name: orgFieldData.fieldName,
            value: ContactFieldUpdates[fieldId],
          });
        }
      }
    }

    if (updateTracking.custom)
      await updateContactCustomFields({
        noteRouterId: props.contactDetails.noteRouterId,
        orgId: selectedOrganization.id,
        existingData: ContactHasCustomData,
        updatedFields: Object.values(customFieldDetails),
      });
    if (updateTracking.basic) {
      await updateContactById({
        ...basicFieldDetails,
        noteRouterId: props.contactDetails.noteRouterId,
        organizationId: selectedOrganization.id,
        updatedDate: moment().unix(),
      });
      props.updatedBasicFields(); //Updates the 'ManageAddForm' drawer to reflect any changes made in this drawer
    }
    if (updateTracking.integrated) {
      if (props.contactDetails?.organization?.attributes) {
        for (const attr of props.contactDetails.organization.attributes) {
          const hasNewValue = integratedFieldDetails.find(
            newAttr => newAttr.name === attr.name
          );
          if (!hasNewValue) integratedFieldDetails.push(attr);
        }
      }
      await updateContactById({
        'organization.attributes': integratedFieldDetails,
        noteRouterId: props.contactDetails.noteRouterId,
        organizationId: selectedOrganization.id,
        updatedDate: moment().unix(),
      });
      props.updatedBasicFields(); //Updates the 'ManageAddForm' drawer to reflect any changes made in this drawer
    }
    setIsLoading({ value: false });
    props.closeDrawer();
  };

  const tableColumns = [
    {
      headerName: 'Field Name',
      field: 'name',
      minWidth: 120,
      flex: 1,
    },
    {
      headerName: 'Value',
      field: 'value',
      minWidth: 120,
      flex: 1,
      editable: true,
      renderCell: params => {
        const not_allowed = [
          'Office Zip Code',
          'Office City',
          'Office State',
          'Office County',
        ];
        return !not_allowed.includes(params.row.name) &&
          (params.row.type === 'Custom' || !APIContact) ? (
          <Tooltip title={'Double click to edit'}>
            <div
              style={{ border: '1px solid lightgray' }}
              className={'tableCellBorder'}
            >
              {params.row.value}
            </div>
          </Tooltip>
        ) : (
          <div style={{ border: '' }} className={'tableCellBorder'}>
            {params.row.value}
          </div>
        );
      },
    },
    {
      headerName: 'Last Updated',
      field: 'lastModified',
      minWidth: 90,
      flex: 0.7,
    },
  ];

  return (
    <div style={{ width: '100%', height: '100%', margin: '0' }}>
      <div className={'FieldDrawerHeader'}>
        <IconButton
          onClick={() => {
            props.closeDrawer();
          }}
          style={{ color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
        {props.drawerTitle}
        <Button
          onClick={() => {
            saveFieldModifications();
          }}
          className={'FieldSaveButtons'}
          variant="outlined"
        >
          Save
        </Button>
      </div>
      <div
        style={{
          height: 'calc(100% - 60px)',
          padding: '12px 16px 20px',
          display: 'flex',
          flexFlow: 'column nowrap',
        }}
      >
        {props.contactDetails?.noteRouterId && userPrivileges?.isSU && (
          <p
            style={{ margin: 0 }}
          >{`NR ID: ${props.contactDetails.noteRouterId}`}</p>
        )}
        {props.contactDetails && props.contactDetails.createdDate && (
          <p style={{ margin: 0 }}>{`Created: ${moment
            .unix(props.contactDetails.createdDate)
            .format('MM-DD-YYYY hh:mm A')}`}</p>
        )}
        {props.contactDetails && props.contactDetails.updatedDate && (
          <p style={{ margin: 0 }}>{`Last Updated: ${moment
            .unix(props.contactDetails.updatedDate)
            .format('MM-DD-YYYY hh:mm A')}`}</p>
        )}
        {props.contactDetails && props.contactDetails.source?.name && (
          <p
            style={{ margin: 0 }}
          >{`Source: ${props.contactDetails.source.name}`}</p>
        )}
        <div className={'ContactFieldsContainer'}>
          {Array.isArray(ContactCurrentCustomFields) && (
            <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
              <DataGrid
                columns={tableColumns}
                rows={tableData}
                sortingOrder={['asc', 'desc']}
                pageSize={pageSize}
                onPageSizeChange={size => setPageSize(size)}
                rowsPerPageOptions={[10, 20, 50]}
                onCellEditCommit={editedCellDetail => {
                  editCustomFieldValue(editedCellDetail);
                }}
                componentsProps={{ panel: { disablePortal: true } }} //There is a weird issue where the filter text field will not work if this is not present for datagrids in drawers.
                isCellEditable={params =>
                  params.row.type === 'Custom' || !APIContact
                }
              />
            </div>
          )}
        </div>
      </div>
      <div
        className="ContactFieldsLoading"
        style={{ display: isLoading.value ? 'flex' : 'none' }}
      >
        <div className="contentDiv">
          <LoadingOutlined />
          <p>{isLoading.text}</p>
        </div>
      </div>
    </div>
  );
};

export default ContactCustomFields;
