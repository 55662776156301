import React from 'react';
import Modal from '../../Modal';

const ConfirmOverwriteModal = ({ apply, onHide }) => {
  return (
    <Modal
      title="Unsaved Changes - test"
      primaryButton={{ onClick: apply }}
      onClose={onHide}
    >
      Applying a template will overwrite your current design and message info.
    </Modal>
  );
};

export default ConfirmOverwriteModal;
