import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router';
import {
  Drawer,
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import { Close, Description, HighlightOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  createOrganization,
  getMultiUserOrgList,
  getOrganizationList,
} from '../../../../../redux/services';
import {
  userPrivilegesSelector,
  userDetailSelector,
} from '../../../../../redux/selectors/user';
import {
  toast,
  organizationList,
  useUpdateUIState,
  userOrganization,
} from '../../../../../redux/action';
import {
  Container,
  Header,
  Content,
  SaveBtn,
  Title,
  Group,
  AddBtn,
  Column,
  ContentTitle,
  Row,
  Bold,
} from './AddOrgDrawer.styled';
import {
  timezones,
  getTimezoneInfo,
  limitCharacters,
  allowOnlyNumericValues,
  isEmpty,
  isEmailValid,
  isValidPhoneNumber,
  isValidWebsite,
  getEmailErrorHelperText,
  getPhoneNumberErrorHelperText,
  getWebsiteErrorHelperText,
} from '../../../../../Pages/Organization/Content/utils';
import PaperFormSubmission from './PaperForm/PaperFormSumbission';

const useStyles = makeStyles(() => ({
  selectLabel: {
    background: 'white',
  },
  selectField: {
    color: '#212121',
    '&:focus': {
      backgroundColor: 'transparent',
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
}));

const CORPORATION_TYPES = {
  Nonprofit: 'Nonprofit/Not for Profit (e.g. a 501(c)3 association)',
  'For-Profit': 'For-Profit (e.g. an MLS)',
  Hybrid: 'Hybrid (e.g. a nonprofit association that owns an MLS)',
};

const EditOrgDetailsDrawer = ({
  isAddOrgDrawerVisible,
  setAddOrgDrawerVisibility,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const userPrivileges = useSelector(userPrivilegesSelector);
  const user = useSelector(userDetailSelector);
  const { fullLoader } = useUpdateUIState();
  const [organizationName, setOrganizationName] = useState('');
  const [acronym, setAcronym] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [website, setWebsite] = useState('');
  const [memberPortalURL, setMemberPortalURL] = useState('');
  const [timezone, setTimezone] = useState('');
  const [corporationType, setCorporationType] = useState('');
  const [ein, setEIN] = useState('');
  const [emailFromName, setEmailFromName] = useState('');
  const [textingFromName, setTextingFromName] = useState('');
  const [fromEmailPrefix, setFromEmailPrefix] = useState('');
  const [fromEmailDomain, setFromEmailDomain] = useState(['']);
  const [fromTextingNumber, setFromTextingNumber] = useState('');
  const [addresses, setAddresses] = useState([
    {
      address: '',
      city: '',
      state: '',
      zipcode: '',
    },
  ]);
  const [isSaveBtnClicked, setSaveBtnClicked] = useState(false);

  const handleAddAddress = () => {
    const lastAddress = addresses[addresses.length - 1];

    if (
      !addresses.length ||
      (lastAddress &&
        lastAddress.address &&
        lastAddress.city &&
        lastAddress.state &&
        lastAddress.zipcode)
    ) {
      setAddresses([
        ...addresses,
        { address: '', city: '', state: '', zipcode: '' },
      ]);
    } else {
      dispatch(
        toast(
          'error',
          'Before creating a new address, ensure that you have completed all the required fields for the existing addresses.'
        )
      );
    }
  };

  const handleAddDomain = () => {
    const lastDomain = fromEmailDomain[fromEmailDomain.length - 1];
    if (lastDomain !== '') {
      setFromEmailDomain([...fromEmailDomain, '']);
    }
  };

  const deleteAddress = index => {
    setAddresses(prevAddresses => prevAddresses.filter((_, i) => i !== index));
  };

  const deleteDomain = index => {
    if (fromEmailDomain.length > 1) {
      const updatedDomains = [...fromEmailDomain];
      updatedDomains.splice(index, 1);
      setFromEmailDomain(updatedDomains);
    }
  };

  const handleChange = (index, field, value) => {
    const updatedAddresses = [...addresses];
    updatedAddresses[index][field] = value;
    setAddresses(updatedAddresses);
  };

  const handleTimeZoneChange = event => {
    setTimezone(event.target.value);
  };

  const closeDrawer = () => {
    setAddOrgDrawerVisibility(false);
  };

  const handleSubmit = async () => {
    try {
      setSaveBtnClicked(true);
      const date = new Date();
      if (checkIfRequiredFieldsAreMissing()) return;
      const id = uuidv4();
      const orgData = {
        id,
        hashId: id.substring(0, 5),
        organizationName,
        acronym,
        address: addresses,
        domains: fromEmailDomain.filter(domain => testDomain(domain)),
        email,
        fromEmail: fromEmailPrefix,
        fromPhone: fromTextingNumber,
        memberPortalURL: memberPortalURL || '',
        phoneNumber,
        senderName: emailFromName,
        timezone: getTimezoneInfo(timezone),
        createdDate: date.toString(),
        updatedDate: date.toString(),
        website,
        federalEIN: ein,
        textSenderName: textingFromName,
        corporationType,
        delete: false,
      };
      fullLoader(true, 'Creating organization...');
      const newOrg = await createOrganization(orgData);
      if (!newOrg) {
        dispatch(toast('error', 'Failed Creating Organization.'));
        fullLoader(false);
        return;
      }
      dispatch(userOrganization(newOrg));

      let userOrgList;

      if (!userPrivileges.isSU) {
        userOrgList = await getMultiUserOrgList(user.organizations);
      } else {
        userOrgList = await getOrganizationList();
      }

      dispatch(organizationList(userOrgList));

      dispatch(toast('success', 'Successfully Created Organization'));
      closeDrawer();
      fullLoader(false);
      history.push('/dashboard/organization');
    } catch (e) {
      dispatch(toast('error', 'Failed Creating Organization.'));
      fullLoader(false);
    }
  };

  const checkIfRequiredFieldsAreMissing = () => {
    const missingRequiredFields = [];
    if (!organizationName) {
      missingRequiredFields.push('Organization Legal Name');
    }

    if (!email) {
      missingRequiredFields.push('Public Email Address');
    }

    if (!phoneNumber) {
      missingRequiredFields.push('Public Phone Number');
    }

    for (const address of addresses) {
      if (
        !address.address ||
        !address.city ||
        !address.state ||
        !address.zipcode
      ) {
        missingRequiredFields.push('Address field');
        break;
      }
    }

    if (!website) {
      missingRequiredFields.push('Website');
    }

    if (!corporationType) {
      missingRequiredFields.push('Corporation Type');
    }

    if (!ein) {
      missingRequiredFields.push('Federal Employer ID Number');
    }

    if (!emailFromName) {
      missingRequiredFields.push('Email "From" Name');
    }

    if (!textingFromName) {
      missingRequiredFields.push("Texting 'From' Name");
    }

    if (!fromEmailPrefix) {
      missingRequiredFields.push("'From' Email Prefix");
    }

    for (const domain of fromEmailDomain) {
      if (!domain) {
        missingRequiredFields.push("'From' Email Domain");
        break;
      }
    }

    if (!fromTextingNumber) {
      missingRequiredFields.push("'From' Texting Number");
    }

    if (!missingRequiredFields.length) return false;
    dispatch(
      toast(
        'error',
        `Please fill out all the required fields: ${missingRequiredFields.join(
          ', '
        )}.`
      )
    );

    return true;
  };

  const testDomain = domain => {
    const pattern = /^[a-zA-Z0-9-]+\.([a-zA-Z]{2,})$/;
    return pattern.test(domain);
  };

  return (
    <Drawer anchor={'right'} onClose={closeDrawer} open={isAddOrgDrawerVisible}>
      <Container>
        <Header>
          <Group>
            <IconButton onClick={closeDrawer} style={{ color: 'white' }}>
              <Close />
            </IconButton>
            <Title>Add: Organization Details</Title>
          </Group>
          <SaveBtn onClick={handleSubmit}>Save</SaveBtn>
        </Header>
        <Content>
          <ContentTitle>Add via PaperForm ID</ContentTitle>
          <PaperFormSubmission />
          <ContentTitle>
            <Description size="small" />
            Organization Details
          </ContentTitle>
          <TextField
            label="Organization Legal Name*"
            variant="outlined"
            size="small"
            value={organizationName}
            error={isSaveBtnClicked && isEmpty(organizationName)}
            helperText={
              isSaveBtnClicked && isEmpty(organizationName)
                ? 'Required field'
                : ''
            }
            onChange={e => setOrganizationName(e.target.value)}
          />
          <TextField
            label="Acronym"
            variant="outlined"
            size="small"
            value={acronym}
            onChange={e => setAcronym(e.target.value)}
          />
          <TextField
            label="Public Email Address*"
            variant="outlined"
            size="small"
            value={email}
            error={isSaveBtnClicked && (isEmpty(email) || !isEmailValid(email))}
            helperText={isSaveBtnClicked && getEmailErrorHelperText(email)}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            label="Public Phone Number*"
            variant="outlined"
            size="small"
            value={phoneNumber}
            error={
              isSaveBtnClicked &&
              (isEmpty(phoneNumber) || !isValidPhoneNumber(phoneNumber))
            }
            helperText={
              isSaveBtnClicked && getPhoneNumberErrorHelperText(phoneNumber)
            }
            onChange={e =>
              setPhoneNumber(allowOnlyNumericValues(e.target.value, 10))
            }
          />
          {addresses.map((address, index) => (
            <Row key={index}>
              <TextField
                label="Street Address*"
                variant="outlined"
                size="small"
                value={address.address}
                error={isSaveBtnClicked && isEmpty(address.address)}
                helperText={
                  isSaveBtnClicked && isEmpty(address.address)
                    ? 'Required field'
                    : ''
                }
                style={{ width: '40%' }}
                onChange={e => handleChange(index, 'address', e.target.value)}
              />
              <TextField
                label="City*"
                variant="outlined"
                size="small"
                value={address.city}
                error={isSaveBtnClicked && isEmpty(address.city)}
                helperText={
                  isSaveBtnClicked && isEmpty(address.city)
                    ? 'Required field'
                    : ''
                }
                style={{ width: '25%' }}
                onChange={e => handleChange(index, 'city', e.target.value)}
              />
              <TextField
                label="State*"
                variant="outlined"
                size="small"
                value={address.state}
                error={isSaveBtnClicked && isEmpty(address.state)}
                helperText={
                  isSaveBtnClicked && isEmpty(address.state)
                    ? 'Required field'
                    : ''
                }
                style={{ width: '12%' }}
                onChange={e => handleChange(index, 'state', e.target.value)}
              />
              <TextField
                label="Zip*"
                variant="outlined"
                size="small"
                value={address.zipcode}
                error={isSaveBtnClicked && isEmpty(address.zipcode)}
                helperText={
                  isSaveBtnClicked && isEmpty(address.zipcode)
                    ? 'Required field'
                    : ''
                }
                style={{ width: '12%' }}
                onChange={e => handleChange(index, 'zipcode', e.target.value)}
              />
              {userPrivileges.isSU && (
                <IconButton
                  onClick={() => deleteAddress(index)}
                  style={{ color: '#ff6161', height: '100%' }}
                >
                  <HighlightOff size="small" />
                </IconButton>
              )}
            </Row>
          ))}
          {userPrivileges.isSU && (
            <AddBtn onClick={handleAddAddress}>+ Add additional address</AddBtn>
          )}
          <TextField
            label="Website*"
            variant="outlined"
            size="small"
            value={website}
            error={
              isSaveBtnClicked && (isEmpty(website) || !isValidWebsite(website))
            }
            helperText={getWebsiteErrorHelperText(website)}
            onChange={e => setWebsite(e.target.value)}
          />
          <TextField
            label="Member Portal URL"
            variant="outlined"
            size="small"
            value={memberPortalURL}
            onChange={e => setMemberPortalURL(e.target.value)}
          />
          <FormControl variant="outlined" size="small">
            <InputLabel className={classes.selectLabel}>Time Zone*</InputLabel>
            <Select
              className={classes.selectField}
              value={timezone}
              onChange={handleTimeZoneChange}
              inputProps={{ 'aria-label': 'Time Zone' }}
              error={isSaveBtnClicked && isEmpty(timezone)}
            >
              {timezones.map(timezone => (
                <MenuItem key={timezone} value={timezone}>
                  {timezone}
                </MenuItem>
              ))}
            </Select>
            {isSaveBtnClicked && isEmpty(timezone) && (
              <FormHelperText
                style={{ color: '#F44335' }}
                id="corporation-type-helper-text"
              >
                Required field
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant="outlined" size="small">
            <InputLabel className={classes.selectLabel}>
              Corporation Type*
            </InputLabel>
            <Select
              className={classes.selectField}
              value={corporationType}
              onChange={e => setCorporationType(e.target.value)}
              inputProps={{ 'aria-label': 'Corporation Type' }}
              error={isSaveBtnClicked && isEmpty(corporationType)}
            >
              <MenuItem disabled value="">
                Corporate Type*
              </MenuItem>
              {Object.entries(CORPORATION_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {isSaveBtnClicked && isEmpty(corporationType) && (
              <FormHelperText
                style={{ color: '#F44335' }}
                id="corporation-type-helper-text"
              >
                Required field
              </FormHelperText>
            )}
          </FormControl>
          <TextField
            label="Federal Employer ID Number (EIN)*"
            variant="outlined"
            size="small"
            value={ein}
            error={isSaveBtnClicked && isEmpty(ein)}
            helperText={
              isSaveBtnClicked && isEmpty(ein) ? 'Required field' : ''
            }
            onChange={e => setEIN(e.target.value)}
          />
          <ContentTitle>
            <Description size="small" />
            Default Sender Details
          </ContentTitle>
          <TextField
            label="Email 'From' Name*"
            variant="outlined"
            size="small"
            value={emailFromName}
            error={isSaveBtnClicked && isEmpty(emailFromName)}
            helperText={
              isSaveBtnClicked && isEmpty(emailFromName) ? 'Required field' : ''
            }
            onChange={e => setEmailFromName(e.target.value)}
          />
          <TextField
            label="Texting 'From' Name* (15 character limit)"
            variant="outlined"
            size="small"
            value={textingFromName}
            error={isSaveBtnClicked && isEmpty(textingFromName)}
            helperText={
              isSaveBtnClicked && isEmpty(textingFromName)
                ? 'Required field'
                : ''
            }
            onChange={e => setTextingFromName(limitCharacters(e.target.value))}
          />
          <Row>
            <TextField
              label="'From' Email Prefix*"
              variant="outlined"
              size="small"
              value={fromEmailPrefix}
              error={isSaveBtnClicked && isEmpty(fromEmailPrefix)}
              helperText={
                isSaveBtnClicked && isEmpty(fromEmailPrefix)
                  ? 'Required field'
                  : ''
              }
              style={{ width: '43%' }}
              onChange={e => setFromEmailPrefix(e.target.value)}
            />
            <Column>
              {fromEmailDomain.map((domain, index) => (
                <Row key={index}>
                  <Bold>@</Bold>
                  <TextField
                    label="'From' Email Domain*"
                    variant="outlined"
                    size="small"
                    value={domain}
                    error={isSaveBtnClicked && isEmpty(domain)}
                    helperText={
                      isSaveBtnClicked && isEmpty(domain)
                        ? 'Required field'
                        : ''
                    }
                    disabled={!userPrivileges.isSU}
                    style={{ width: '80%' }}
                    onChange={e => {
                      const updatedDomains = [...fromEmailDomain];
                      updatedDomains[index] = e.target.value;
                      setFromEmailDomain(updatedDomains);
                    }}
                  />
                  {userPrivileges.isSU && (
                    <IconButton
                      onClick={() => deleteDomain(index)}
                      style={{ color: '#ff6161', height: '100%' }}
                    >
                      <HighlightOff size="small" />
                    </IconButton>
                  )}
                </Row>
              ))}
            </Column>
          </Row>
          {userPrivileges.isSU && (
            <AddBtn onClick={handleAddDomain}>+ Add additional domain</AddBtn>
          )}
          <TextField
            label="'From' Texting Number*"
            variant="outlined"
            size="small"
            value={fromTextingNumber}
            error={isSaveBtnClicked && isEmpty(fromTextingNumber)}
            helperText={
              isSaveBtnClicked && isEmpty(fromTextingNumber)
                ? 'Required field'
                : ''
            }
            onChange={e => setFromTextingNumber(e.target.value)}
          />
        </Content>
      </Container>
    </Drawer>
  );
};

export default EditOrgDetailsDrawer;
