import * as Handlebars from 'handlebars/dist/cjs/handlebars';
import React, { useRef, useEffect } from 'react';
import { Card } from '../_CardUI/Card.styled';
import { Container, ImageContainer, Text } from './Thumbnail.styled';
import { ReactComponent as EmptyDesignIcon } from '../_CardUI/bulb.svg';

// this is the length of an empty html file
const MINIMUM_CHAR_NUMBER = 2477;
const APPROXIMATE_CARD_HEIGHT = 240;
// this is the number to scale down html file to
const SCALE = 0.4;

const Thumbnail = ({ html }) => {
  const iframeRef = useRef(null);
  const iframeFormatting = `
  <style type="text/css">
    html, body {
      margin: 0;
      padding: 0;
      height: 100%;
      overflow: hidden;
    }
    body {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      background-color: white !important;
    }
    #content {
      width: 100%;
      transform-origin: top left;
      transform: scale(1);
    }
    </style>
    </head>
    <body>
    <div id="content">
    `;

  const iframeClosing = `
    </div>
  </body>
  `;

  let preview = '';

  Handlebars.registerHelper('if', function (context, options) {
    return `<div>${options.fn(context)}</div>`;
  });

  Handlebars.registerHelper('subscribedToGroups', function () {
    return;
  });

  try {
    if (html?.length > MINIMUM_CHAR_NUMBER) {
      preview = Handlebars.compile(
        (' ' + html)
          .slice(1)
          .replace(/<\/head>/g, iframeFormatting)
          .replace(/<\/body>/g, iframeClosing)
      )();
    }
  } catch (err) {
    //If the HTML fails to parse, it will crash the entire app... Gracefully fail and set the thumbnail to be blank
    console.error('Failed to parse HTML', err);
    preview = '';
  }

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      const resizeContent = () => {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        const content = iframeDocument.getElementById('content');

        if (content) {
          content.style.transform = `scale(${SCALE})`;

          content.style.height = 'auto';

          const scaledContentHeight = content.offsetHeight * SCALE;

          if (scaledContentHeight < APPROXIMATE_CARD_HEIGHT) {
            // if the content height is smaller than the card height then add some padding at the top, i.e. use translateY to move design down
            const val = APPROXIMATE_CARD_HEIGHT - scaledContentHeight;
            content.style.transform = `scale(${SCALE}) translateY(${val}px)`;
          } else {
            // if design height is more than card height then place design at the very top of the card
            content.style.position = 'static';
            content.style.top = '0';
            content.style.transform = `scale(${SCALE})`;
          }
        }
      };

      iframe.onload = resizeContent;
      window.addEventListener('resize', resizeContent);

      return () => window.removeEventListener('resize', resizeContent);
    }
  }, []);
  return (
    <Card.Thumbnail>
      {html?.length > MINIMUM_CHAR_NUMBER && preview ? (
        <ImageContainer>
          <iframe
            ref={iframeRef}
            title="Design Preview"
            srcDoc={preview}
            style={{
              width: '250%',
              height: '250%',
              border: 'none',
              overflow: 'hidden',
            }}
          />
        </ImageContainer>
      ) : (
        <Container>
          <EmptyDesignIcon />
          <Text>(No design yet)</Text>
        </Container>
      )}
    </Card.Thumbnail>
  );
};

export default Thumbnail;
