import React from 'react';
import { Tooltip } from '@material-ui/core';
import { ErrorRounded } from '@material-ui/icons';
import styled from 'styled-components';

const TextHelp = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  padding-bottom: 4px;
  vertical-align: middle;
  font-size: 9px;
`;

const WaringCopyTooltip = () => (
  <TextHelp>
    <Tooltip title="Unfortunately, telecom carriers do not allow shortened URLs in automated texts sent from traditional phone numbers and will block your replies from sending.">
      <ErrorRounded style={{ fontSize: '14px' }} />
    </Tooltip>
    <span style={{ fontWeight: 900, textShadow: '0.3px 0' }}>
      Do not use shortened URLs in replies
    </span>
    <span>(e.g. bit.ly)</span>
  </TextHelp>
);

export default WaringCopyTooltip;
