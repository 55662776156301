import styled from 'styled-components';
import { Input } from 'antd';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FilterContainer = styled.div`
  padding: 10px;
  display: flex;
  gap: 10px;
`;

export const Menu = styled.ul`
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding-left: 25px;
`;

export const MenuItem = styled.li`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: #007ade;
  border: 1px solid #007ade;
  border-radius: 5px;
  background-color: #007ade05;
  transition: background-color 0.3s;
  font-size: 16px;
  padding: 7px 15px;
  margin-right: 6px;
  line-height: 14px;
  display: flex;
  text-align: center;
  align-items: center;
  height: 40px;

  &:hover {
    cursor: pointer;
    background-color: #007ade0f;
    border: 1px solid #007ade;
    color: #007ade;
  }

  &.active {
    cursor: pointer;
    color: white;
    background-color: #007ade;
    border: 1px solid #007ade;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
`;

export const SearchInput = styled(Input)`
  min-width: 180px;
  border-radius: 5px;

  @media (min-width: 1500px) {
    width: 220px;
  }

  @media (min-width: 1600px) {
    width: 360px;
  }
`;
