import styled from 'styled-components';
import { DatePicker as AntdDatePicker } from 'antd';

const DatePicker = styled(AntdDatePicker)`
  input {
    border-color: #90b9da;
    height: auto;
  }
`;

export default DatePicker;
