import React from 'react';

import {
  formatTimestampToDate,
  trimTextIfNeeded,
} from '../../../../../../Component/Drafts/Components/_utils';
import {
  Card,
  CardHeader,
} from '../../../../../../Component/Drafts/Components/_CardUI/Card.styled';
import {
  ImageHolder,
  Image,
  StyledLogo,
} from '../../../../../../Component/Drafts/Components/TemplateCard/TemplateCard.styled';

const TemplateCard = ({ onClick, template }) => {
  return (
    <Card pointer onClick={onClick}>
      <ImageHolder>
        <Image src={template.thumbnail} alt={template.name + ' thumbnail'} />
      </ImageHolder>
      <Card.Body>
        <CardHeader>
          <CardHeader.Left>
            <StyledLogo />
          </CardHeader.Left>
        </CardHeader>
        <Card.Name>{trimTextIfNeeded(template.name, 60)}</Card.Name>
        <Card.Date>
          Released: {formatTimestampToDate(template.saveTime, true)}
        </Card.Date>
      </Card.Body>
    </Card>
  );
};

export default TemplateCard;
