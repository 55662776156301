import React from 'react';
import { ArrowForwardOutlined } from '@material-ui/icons';
import { Tooltip } from 'antd';
import { Card, Button, CardHeader } from '../_CardUI/Card.styled';
import { ImageHolder, Image, StyledLogo } from './TemplateCard.styled';
import { formatTimestampToDate, trimTextIfNeeded } from '../_utils';

const TemplateCard = ({ template, onClick }) => {
  return (
    <Card>
      <ImageHolder>
        <Image src={template.thumbnail} alt="template" />
      </ImageHolder>
      <Card.Body>
        <CardHeader>
          <CardHeader.Left>
            <StyledLogo />
          </CardHeader.Left>
        </CardHeader>
        <Card.Name>{trimTextIfNeeded(template.name, 60)}</Card.Name>
        <Card.Date>
          Released: {formatTimestampToDate(template.saveTime, true)}
        </Card.Date>
        <Tooltip title="Opens a new draft using this template">
          <Button onClick={onClick}>
            Use
            <ArrowForwardOutlined style={{ fontSize: '14px' }} />
          </Button>
        </Tooltip>
      </Card.Body>
    </Card>
  );
};

export default TemplateCard;
