import React from 'react';
import { ArrowBackSharp } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { IconButton } from './BackButton.styled';

const BackButton = ({ onClick, tooltipText = 'Go Back' }) => {
  return (
    <Tooltip title={tooltipText} arrow>
      <IconButton size="small" onClick={onClick}>
        <ArrowBackSharp />
      </IconButton>
    </Tooltip>
  );
};

export default BackButton;
