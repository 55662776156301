import React from 'react';
import { Container, Title, AddIcon } from './NewCard.styled';
import { Card } from '../_CardUI/Card.styled';

const NewCard = ({ onClick, title }) => {
  return (
    <Card pointer onClick={onClick}>
      <Container>
        <AddIcon />
        <Title>{title}</Title>
      </Container>
    </Card>
  );
};

export default NewCard;
