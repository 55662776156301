import styled from 'styled-components';
import {
  EditOutlined,
  CopyOutlined,
  FolderOpenOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { Delete, StarRounded } from '@material-ui/icons';

const createAction = (BaseIcon, { hoverColor = '#007ade' } = {}) =>
  styled(BaseIcon)`
    transition: color 0.5s;

    &:hover {
      color: ${hoverColor};
      cursor: pointer;
    }
  `;

export const EditButton = styled(createAction(EditOutlined))`
  color: rgba(24, 144, 255, 0.75);
`;

export const FavoriteButton = styled(
  createAction(StarRounded, { hoverColor: '#ffae00' })
)`
  ${({ $starred }) => $starred && 'color: #ffae00'}
`;

export const MoveButton = createAction(FolderOpenOutlined);

export const CopyButton = createAction(CopyOutlined);

export const ArchiveButton = createAction(Delete, {
  hoverColor: 'red',
});

export const UnarchiveButton = createAction(CheckCircleOutlined, {
  hoverColor: 'green',
});

export const EmptySpace = styled.div`
  width: 10px;
  height: 15px;
`;
