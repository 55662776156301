import styled from 'styled-components';

const Button = styled.button`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: #007ade;
  border: 1px solid #007ade;
  border-radius: 5px;
  background-color: #007ade05;
  transition: background-color 0.3s;
  font-size: 16px;
  padding: 7px 15px;
  margin-right: 6px;
  line-height: 14px;
  display: flex;
  text-align: center;
  align-items: center;
  height: 40px;

  &:hover {
    cursor: pointer;
    background-color: #007ade0f;
    border: 1px solid #007ade;
    color: #007ade;
  }

  &.active {
    cursor: pointer;
    color: white;
    background-color: #007ade;
    border: 1px solid #007ade;
  }

  &:disabled {
    cursor: not-allowed;
    color: grey;
    border-color: grey;
    background-color: transparent;
  }
`;

export default Button;
