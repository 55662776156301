import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

export const Text = styled.span`
  font-size: 11px;
  color: #adabab;
`;

export const ImageContainer = styled.div`
  height: calc(100% - 20px);
  width: 100%;
  padding: 20px 0 20px 20px;
  overflow: hidden;
`;
