import React, { useState, useRef, useEffect, useCallback } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CircularProgress } from '@material-ui/core';
import { selectedOrganizationSelector } from '../../../../redux/selector';
import {
  fetchOrgTopicsAndGroups,
  getGroupTypes,
  getOrgAudiences,
  updateAudience,
} from '../../../../redux/services';
import {
  fullloader,
  toast,
  applyAudienceToExistingDraftAction,
  resetAudienceBuilder,
  loadGroups,
  loadTopics,
  loadGroupTypes,
} from '../../../../redux/action';
import NewCard from '../NewCard';
import AudienceCard from '../AudienceCard';
import {
  Container,
  MessageContainer,
  FullLengthContainer,
  Text,
} from '../_PageUI/Page.styled';
import useCountCardsPerRow from '../_utils/useCountCardsPerRow';
import { ROWS_TO_LOAD } from '../_utils/constants';

const SavedAudiencesPage = ({
  activeSortByValue,
  activeFilter,
  cardsBySearch,
  loadingCardsBySearch,
  searchValue,
}) => {
  const history = useHistory();
  const location = useLocation();
  const orgId = useSelector(selectedOrganizationSelector).id;
  const dispatch = useDispatch();
  const observer = useRef();
  const [audienceCards, setAudienceCards] = useState([]);
  const [hasMoreCardsToLoad, setHasMoreCardsToLoad] = useState(false);
  const [loadingMoreAudienceCards, setLoadingMoreAudienceCards] =
    useState(false);
  const cardsPerRow = useCountCardsPerRow();
  const hasSomeAudienceCardsRendered = audienceCards.length > 0;
  const loadTopicsAndGroups = useCallback(async () => {
    const { tags, topics } = await fetchOrgTopicsAndGroups(orgId);
    dispatch(loadGroups(tags));
    dispatch(loadTopics(topics));
    const groupTypes = await getGroupTypes(orgId);

    dispatch(loadGroupTypes(groupTypes));
  }, [dispatch, orgId]);

  const {
    apply,
    tab,
    draft: draftId,
    audienceId,
  } = queryString.parse(location.search);
  const isApplyingAudience = apply === '1' && tab === 'audiences';

  useEffect(() => {
    // check if we can make sure that groups are loaded once per org
    if (orgId) {
      loadTopicsAndGroups();
    }
  }, [loadTopicsAndGroups, orgId]);

  const loadSavedAudiences = useCallback(async () => {
    try {
      setLoadingMoreAudienceCards(true);
      const skip = audienceCards.length;
      let limit = cardsPerRow * ROWS_TO_LOAD;

      // very first api request should retrieve one card less there is already a NewCard rendered
      if (!skip) {
        limit -= 1;
      }

      const sort =
        activeSortByValue === 'name' ? activeSortByValue : '-updatedAt';

      let filter = 'active';
      if (activeFilter === 'archived') {
        filter = 'archived';
      } else if (activeFilter === 'starred') {
        filter = 'starred';
      }

      const data = await getOrgAudiences({
        orgId,
        skip,
        limit,
        sort,
        filter,
      });

      setAudienceCards(prevSavedAudiences => [
        ...prevSavedAudiences,
        ...data.result,
      ]);
      setHasMoreCardsToLoad(data.left);
      setLoadingMoreAudienceCards(false);
    } catch (e) {
      dispatch(toast('error', 'Error loading saved audiences.'));
    }
  }, [
    activeFilter,
    activeSortByValue,
    audienceCards.length,
    cardsPerRow,
    dispatch,
    orgId,
  ]);

  // triggers next useEffect which triggers loadSavedAudiences function
  useEffect(() => {
    setAudienceCards([]);
  }, [activeSortByValue, activeFilter, orgId]);

  useEffect(() => {
    if (orgId && audienceCards.length === 0) {
      dispatch(fullloader(true, 'Loading Audiences ...'));
      loadSavedAudiences().finally(() => {
        dispatch(fullloader(false));
      });
    }
  }, [orgId, audienceCards.length, dispatch, loadSavedAudiences]);

  const lastElemComponentRef = lastElement => {
    if (hasMoreCardsToLoad) {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          loadSavedAudiences();
        }
      });

      if (lastElement) observer.current.observe(lastElement);
    }
  };

  const openNewAudience = () => {
    dispatch(resetAudienceBuilder());
    history.push('/dashboard/audience?new=true');
  };

  const applyingAudience = async audience => {
    if (draftId) {
      dispatch(fullloader(true, 'Applying audience to existing draft.'));
      await dispatch(applyAudienceToExistingDraftAction(draftId, audience));
      dispatch(fullloader(false));
      history.push(`/dashboard/audience?draft=${draftId}`);
    } else if (audienceId) {
      dispatch(fullloader(true, 'Applying saved audience...'));
      const updatedAudience = {
        _id: audienceId,
        topicIds: audience.topicIds,
        selectedGroupIds: audience.selectedGroupIds,
        requiredGroupIds: audience.requiredGroupIds,
        excludedGroupIds: audience.excludedGroupIds,
        includedMemberIds: audience.includedMemberIds,
        excludedMemberIds: audience.excludedMemberIds,
        filterData: audience.filterData,
      };
      await updateAudience(updatedAudience);
      dispatch(fullloader(false));
      history.push(`/dashboard/audience?audienceId=${audienceId}`);
    } else {
      dispatch(
        toast(
          'error',
          'Could not fetch draft id or audience id to apply audience to.'
        )
      );
      history.push('/dashboard/drafts');
    }
  };
  const savedAudiencesText =
    'An Audience is a saved selection of targeting criteria. Saved Audiences make it easy to reuse fancy targeting without having to redo it for every message.';

  const getSearchResults = () => {
    if (loadingCardsBySearch)
      return (
        <FullLengthContainer>
          <CircularProgress size={50} />
        </FullLengthContainer>
      );

    if (cardsBySearch.length)
      return cardsBySearch.map(audience => (
        <AudienceCard
          key={audience._id}
          reRender={() => setAudienceCards([])}
          audience={audience}
          applyAudience={() => {
            applyingAudience(audience);
          }}
          isApplyingAudience={isApplyingAudience}
        />
      ));

    return <>No Audiences found.</>;
  };

  return (
    <>
      <MessageContainer>
        <InfoCircleOutlined />
        <Text>{savedAudiencesText}</Text>
      </MessageContainer>
      <Container>
        {cardsBySearch.length || loadingCardsBySearch || searchValue ? (
          getSearchResults()
        ) : (
          <>
            {!isApplyingAudience && (
              <NewCard
                onClick={openNewAudience}
                title="Create a New Audience"
              />
            )}
            {audienceCards.map(audience => (
              <AudienceCard
                key={audience._id}
                reRender={() => setAudienceCards([])}
                audience={audience}
                applyAudience={() => {
                  applyingAudience(audience);
                }}
                isApplyingAudience={isApplyingAudience}
              />
            ))}
            {hasSomeAudienceCardsRendered &&
              (loadingMoreAudienceCards ? (
                <FullLengthContainer>
                  <CircularProgress size={50} />
                </FullLengthContainer>
              ) : (
                <FullLengthContainer ref={lastElemComponentRef} />
              ))}
          </>
        )}
      </Container>
    </>
  );
};

export default SavedAudiencesPage;
