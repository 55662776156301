import styled from 'styled-components';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 15px;
  justify-content: center;
  width: 100%;
`;

export const AddIcon = styled(PlusCircleOutlined)`
  && {
    font-size: 90px;
    color: #007ade;
  }
`;

export const Title = styled.h2`
  font-size: 26px;
  color: #007ade;
  font-weight: bold;
`;
