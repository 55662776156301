import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import useUserAuth from '../../utils';
import { gAnalytics } from '../../redux/helpers/index';
import Tabs from './Components/Tabs';
import Drafts from './Components/DraftsPage';
import SavedTemplates from './Components/SavedTemplatesPage';
import SharedTemplates from './Components/SharedTemplatesPage';
import SavedAudiences from './Components/SavedAudiencesPage';
import {
  ACTIVE,
  SCHEDULED,
  ARCHIVED,
  AUDIENCES_TAB,
  DATE,
  DRAFTS,
  FAVOURITED,
  NAME,
  SAVED_AUDIENCES,
  SAVED_TEMPLATES,
  SAVED_TEMPLATES_TAB,
  SENT,
  SHARED_TEMPLATES,
  SHARED_TEMPLATES_TAB,
} from './Components/_utils/constants';

const Draft = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useUserAuth();

  useEffect(() => {
    gAnalytics('pageview', '/send-message');
  }, [dispatch]);

  const renderedTabs = useMemo(() => {
    const { apply, tab } = queryString.parse(location.search);
    const savedTemplatesTabContent = {
      menuItemName: SAVED_TEMPLATES,
      content: SavedTemplates,
      tab: SAVED_TEMPLATES_TAB,
      sort: [DATE, NAME],
      filter: [ACTIVE, ARCHIVED, FAVOURITED],
    };
    const sharedTemplatesTabContent = {
      menuItemName: SHARED_TEMPLATES,
      content: SharedTemplates,
      tab: SHARED_TEMPLATES_TAB,
    };
    const savedAudiencesTabContent = {
      menuItemName: SAVED_AUDIENCES,
      content: SavedAudiences,
      tab: AUDIENCES_TAB,
      sort: [DATE, NAME],
      filter: [ACTIVE, ARCHIVED, FAVOURITED],
    };
    // applying a saved audience to a current draft audience
    if (apply === '1' && tab === 'audiences') {
      return [savedAudiencesTabContent];
      // applying a saved design or shared design to a current draft
    } else if (apply === '1') {
      return [savedTemplatesTabContent, sharedTemplatesTabContent];
    } else {
      return [
        {
          menuItemName: DRAFTS,
          content: Drafts,
          sort: [DATE, NAME],
          filter: [ACTIVE, SCHEDULED, SENT, ARCHIVED],
        },
        savedTemplatesTabContent,
        sharedTemplatesTabContent,
        savedAudiencesTabContent,
      ];
    }
  }, [location.search]);

  return <Tabs content={renderedTabs} />;
};

export default Draft;
