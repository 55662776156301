import React from 'react';

import {
  formatDate,
  trimTextIfNeeded,
} from '../../../../../../Component/Drafts/Components/_utils';
import {
  Card,
  CardHeader,
} from '../../../../../../Component/Drafts/Components/_CardUI/Card.styled';
import Thumbnail from '../../../../../../Component/Drafts/Components/Thumbnail';

const TemplateCard = ({ onClick, template }) => {
  return (
    <Card pointer onClick={onClick}>
      <Thumbnail html={template.html} />
      <Card.Body>
        <CardHeader />
        <CardHeader />
        <Card.Date>{formatDate(template.saveTime)}</Card.Date>
        <Card.Name>{trimTextIfNeeded(template.name, 60)}</Card.Name>
        {template.subject && (
          <Card.Subject>
            <b>Subject</b>
            <br />
            {trimTextIfNeeded(template.subject, 54)}
          </Card.Subject>
        )}
      </Card.Body>
    </Card>
  );
};

export default TemplateCard;
