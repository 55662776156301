import React, { Component } from 'react';
import moment from 'moment-timezone';
import * as services from '../../redux/services';
import 'antd/dist/antd.css';
import './RecipientTable.css';
import {
  SearchOutlined,
  MailTwoTone,
  LoadingOutlined,
  MobileTwoTone,
} from '@ant-design/icons';
import { Table, Input, Button, Space } from 'antd';
import { CSVLink } from 'react-csv/lib';
import generateRecipientTableCSV from './utils';
import { TableContainer, TextBubble } from './RecipientTable.styled';
class RecipientTable extends Component {
  constructor(props) {
    super();
    this.state = {
      dataFound: false,
      draftId: window.location.href.split('?draft=')[1],
      tableData: [],
      tableSize: this.initializeTableSize(props),
      tableSizeOptions: ['15', '25', '50', '100'],
      csv: false,
      loading: true,
    };
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={'Search'}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text => text,
  });

  generateRows = async () => {
    let recipientsMap = {};
    let tableData;

    if (this.props.tag && this.props.memberList) {
      // Get Members based on Identifier
      if (this.props.tag.groupTypeId)
        tableData = await services.getContactsByGroup(
          this.props.tag.id,
          'identifier'
        );
      // Get Members based on Topic
      else
        tableData = await services.getContactsByGroup(
          this.props.tag.id,
          'Topic'
        );
    } else {
      for (let recipient of this.props.message.emailRecipients) {
        recipientsMap[recipient.noteRouterId] = {
          ...recipient,
          fullName: `${recipient.firstName || ''} ${recipient.lastName || ''}`,
          NRDS: recipient.contactNRDSID,
          contactMethods: [true, false],
          key: recipient.noteRouterId,
          memberKeyNumeric: recipient.amsContactID,
        };
      }
      for (let recipient of this.props.message.textRecipients) {
        if (recipientsMap[recipient.noteRouterId]) {
          recipientsMap[recipient.noteRouterId].contactMethods[1] = true;
        } else {
          recipientsMap[recipient.noteRouterId] = {
            ...recipient,
            fullName: `${recipient.firstName || ''} ${
              recipient.lastName || ''
            }`,
            NRDS: recipient.contactNRDSID,
            contactMethods: [false, true],
            key: recipient.noteRouterId,
            memberKeyNumeric: recipient.amsContactID,
          };
        }
      }
      let values = Object.values(recipientsMap);
      tableData = values && Array.isArray(values) ? values : [];
    }

    this.setState({ tableData, dataFound: true, loading: false });
  };

  generateColumns = () => {
    if (this.props.tag && !this.props.tag.groupTypeId) {
      return [
        {
          title: 'Member',
          dataIndex: 'fullName',
          key: 'fullName',
          ...this.getColumnSearchProps('fullName'),
        },
        {
          title: 'Member ID',
          dataIndex: 'memberKeyNumeric',
          key: 'memberKeyNumeric',
          ...this.getColumnSearchProps('memberKeyNumeric'),
        },
        {
          title: 'NRDS ID',
          dataIndex: 'NRDS',
          key: 'NRDS',
          ...this.getColumnSearchProps('NRDS'),
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          ...this.getColumnSearchProps('email'),
        },
        {
          title: 'Phone',
          dataIndex: 'phoneNumber',
          key: 'phoneNumber',
          ...this.getColumnSearchProps('phoneNumber'),
        },
        {
          title: 'Delivery',
          dataIndex: 'contactMethods',
          key: 'contactMethods',
          render: methods =>
            methods.map((method, index) => {
              if (method && index === 0)
                return <MailTwoTone key={method + '-' + index} />;
              else if (method && index === 1)
                return <MobileTwoTone key={method + '-' + index} />;
            }),
        },
      ];
    } else if (this.props.tag) {
      return [
        {
          title: 'Member',
          dataIndex: 'fullName',
          key: 'fullName',
          ...this.getColumnSearchProps('fullName'),
        },
        {
          title: 'Member ID',
          dataIndex: 'memberKeyNumeric',
          key: 'memberKeyNumeric',
          ...this.getColumnSearchProps('memberKeyNumeric'),
        },
        {
          title: 'NRDS ID',
          dataIndex: 'NRDS',
          key: 'NRDS',
          ...this.getColumnSearchProps('NRDS'),
        },
      ];
    } else {
      return [
        {
          title: 'Member',
          dataIndex: 'fullName',
          key: 'fullName',
          ...this.getColumnSearchProps('fullName'),
        },
        {
          title: 'Delivery',
          dataIndex: 'contactMethods',
          key: 'contactMethods',
          render: methods =>
            methods.map((method, index) => {
              if (method && index === 0) return <MailTwoTone key={index} />;
              else if (method && index === 1)
                return <MobileTwoTone key={index} />;
            }),
        },
      ];
    }
  };

  initializeTableSize = props => {
    if (!props.tag) {
      return (
        JSON.parse(
          localStorage.getItem(
            `table-${window.location.href.split('?draft=')[1]}`
          )
        )?.tableSize || 15
      );
    } else {
      return (
        JSON.parse(localStorage.getItem(`table-${props.tag.id}`))?.tableSize ||
        15
      );
    }
  };

  saveTableSize = size => {
    if (!this.props.tag) {
      const draftId = this.state.draftId;
      localStorage.setItem(
        `table-${draftId}`,
        JSON.stringify({ draftId, tableSize: size })
      );
    } else {
      const tagId = this.props.tag.id;
      localStorage.setItem(
        `table-${tagId}`,
        JSON.stringify({ tableSize: size })
      );
    }
  };

  calculateCommaPlacement = numVal =>
    numVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleReset = clearFilters => {
    clearFilters();
  };

  componentDidMount = () => {
    if (!this.state.tableData.length && !this.state.dataFound) {
      this.generateRows();
    }
    this.calcTableHeight();
  };

  calcTableHeight = rows => {
    // 37px is the height of one row
    // 600px is approximately what it takes to have a text bubble that informs
    // the user about AB messages splitting audience into two
    // if rows are not passed, tableSize is used, and if it is not defined then 15 rows
    // per table is used as a default value
    if (
      window.innerHeight - (rows || this.state.tableSize || 15) * 37 - 600 <
      0
    ) {
      this.setState({
        ABdraftTableHeight: `${window.innerHeight - 400}px`,
      });
    }
  };

  render = () => {
    // if the table is rendered via message preview, use the savetime. otherwise, use the download date
    const saveTime = this.props.message?.saveTime
      ? `${moment.unix(this.props.message.saveTime).format('MM-DD-YY')}`
      : moment().format('MM-DD-YY');

    // the start of the filename should be the subject if the table is rendered via message preview. otherwise use tag name
    const filenameHeader = this.props.message?.subject || this.props.tag?.name;
    const filename = `${filenameHeader?.replaceAll(' ', '-')}-${saveTime}.csv`;

    const numberOfRecipients = this.state.tableData.length;
    const isABdraft =
      this.props.isABFeatureEnabled &&
      this.props.message.AB &&
      this.props.message.AB.version;

    return (
      <div className="recipient-table-container">
        <div className="recipient-table-header">
          <h5>
            <b>{`${this.props.tag ? 'Contacts' : 'Recipients'} (${
              numberOfRecipients
                ? this.calculateCommaPlacement(numberOfRecipients)
                : ''
            })`}</b>
          </h5>
          {!this.state.loading && (
            <CSVLink
              filename={filename}
              className="recipient-table-btn"
              data={generateRecipientTableCSV({
                dataType: this.props.tag ? 'tag' : 'message',
                data: this.state.tableData,
              })}
            >
              Export
            </CSVLink>
          )}
        </div>

        {!this.state.loading ? (
          <>
            <TableContainer height={isABdraft && this.state.ABdraftTableHeight}>
              <Table
                columns={this.generateColumns()}
                dataSource={this.state.tableData}
                data-testid="recipient-table"
                pagination={{
                  defaultPageSize: Number(this.state.tableSize),
                  pageSizeOptions: this.state.tableSizeOptions,
                  showSizeChanger: true,
                  onShowSizeChange: (_, size) => {
                    this.calcTableHeight(size);
                    this.saveTableSize(size);
                    this.setState({ tableSize: size });
                  },
                }}
              />
            </TableContainer>
            {isABdraft && (
              <TextBubble>
                For A/B messages, a random 50% of the targeted recipients will
                be sent version A, and the other 50% will be sent version B.
                Resending an A/B message multiple times will result in a
                different distribution of version A and B each time.
              </TextBubble>
            )}
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              height: '65vh',
            }}
            className="recipientTableLoading"
          >
            <LoadingOutlined style={{ fontSize: '36px' }} />
            <p style={{ marginTop: '10px' }}>
              Please wait, this may take a minute.
            </p>
          </div>
        )}
      </div>
    );
  };
}

export default RecipientTable;
