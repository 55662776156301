import { useInfiniteQuery, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { selectedOrganizationSelector } from '../../selectors/organization';
import { getTemplates, getUserTemplates } from '../template';

export const useUserTemplatesQuery = (
  { getChunkSize, sortBy = '-saveTime', status = 'active' },
  options
) => {
  const activeOrganization = useSelector(selectedOrganizationSelector);

  const query = useInfiniteQuery(
    ['templates', 'user', 'index', { sortBy, status }],
    async ({ pageParam: cursor = 0 }) => {
      const limit = getChunkSize(cursor);
      const { result: templates, left } = await getUserTemplates({
        orgId: activeOrganization.id,
        skip: cursor,
        limit,
        sortby: sortBy,
        filter: status,
      });

      const result = { templates };
      if (left) {
        result.nextCursor = cursor + limit;
      }

      return result;
    },
    {
      ...options,
      getNextPageParam(page) {
        return page.nextCursor;
      },
    }
  );

  const data = query.data
    ? query.data.pages.flatMap(page => page.templates)
    : [];
  return {
    ...query,
    data,
    isAnyPageLoaded: data.length > 0,
  };
};

const RAP_ORG_TYPES = [
  'ab94c230-b9c6-4f3b-bc71-d30fecec0a05', // rap staging
  'da80310b-5712-4497-b4c4-9989241e1324', // rap live
];

export const useSharedTemplatesQuery = (options, orgType) => {
  const query = useQuery(
    ['templates', 'shared', 'index'],
    () => getTemplates(orgType),
    options
  );
  const orgData = useSelector(selectedOrganizationSelector);
  let filteredTemplates = query.data;

  if (
    //If a Rapattoni AMS org and they don't have COE enabled, remove the COE template
    query.data &&
    RAP_ORG_TYPES.includes(orgData.orgType) &&
    !orgData.magicCOEEnabled
  ) {
    filteredTemplates = query.data.filter(
      template => template.name !== 'Code of Ethics Compliance Notice'
    );
  }

  return { ...query, data: filteredTemplates ?? [] };
};
