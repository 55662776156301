import React, { useEffect, useState, useMemo, useRef } from 'react';
import SelectStyled from './select.styled';
import debounce from 'lodash/debounce';

const EventsAndClassesIdSelector = ({
  eventId,
  setEventId,
  placeholder,
  fetchDataFunc,
  shouldReturnUniqueValues,
}) => {
  const { Option } = SelectStyled;
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const pageRef = useRef(0);
  const debounceTimeout = 800;

  const fetchEventData = async searchText => {
    setLoading(true);

    //When searching, we need to reset the dropdown to only show the searched values
    const currentValues = searchText ? [] : eventData;

    if (searchText) {
      setEventData([]);
      pageRef.current = 0;
      setEventId(null);
    }

    const eventsandclassesData = await fetchDataFunc({
      page: pageRef.current,
      searchValue: searchText || searchValue,
    });

    const normalizedEventsAndClassesData =
      eventsandclassesData.map(transformEventData);

    let sortedEventsAndClassesData = [
      ...currentValues,
      ...normalizedEventsAndClassesData,
    ].sort((a, b) => a.name.localeCompare(b.name));

    if (shouldReturnUniqueValues) {
      const valueExists = {};

      sortedEventsAndClassesData = sortedEventsAndClassesData.filter(
        ({ value }) => {
          if (valueExists[value]) {
            return false;
          } else {
            valueExists[value] = true;
            return true;
          }
        }
      );
    }

    setEventData(sortedEventsAndClassesData);
    setLoading(false);
    pageRef.current += 1;
  };

  const transformEventData = event => {
    return {
      value: event.eventId || event.courseId,
      name: event.name,
      display: `[ID: ${event.eventId || event.courseId}] ${event.name}`,
    };
  };

  const handleSearch = useMemo(() => {
    const fetchData = async value => {
      setSearchValue(value);
      await fetchEventData(value);
    };

    return debounce(fetchData, debounceTimeout);
  }, [fetchEventData, debounceTimeout]);

  const handleScroll = async ({ target }) => {
    if (
      !loading &&
      // doing this trick with '+ 2' to trigger scrolling when mouse is close to the end of data in dropdown select
      target.scrollTop + target.offsetHeight + 2 > target.scrollHeight
    ) {
      target.scrollTo(0, target.scrollHeight);
      fetchEventData();
    }
  };

  useEffect(() => {
    fetchEventData();
  }, []);

  return (
    <SelectStyled
      placeholder={placeholder}
      filterOption={false}
      showSearch
      onSearch={handleSearch}
      onPopupScroll={handleScroll}
      loading={loading}
      value={eventId || null}
      style={{ width: '150px' }}
      onChange={eventId => setEventId(eventId)}
      dropdownMatchSelectWidth={false}
      allowClear
    >
      {eventData.map(({ value, display }) => {
        return (
          <Option key={value} value={value}>
            {display}
          </Option>
        );
      })}
    </SelectStyled>
  );
};

export default EventsAndClassesIdSelector;
