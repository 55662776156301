import styled from 'styled-components';

export const DisplayAlert = styled.p`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  background-color: #fc9e3a;
  padding: 6px 8px;
  border-radius: 8px;
  color: #fff;
  font-weight: 400;
  max-width: 500px;
  font-family: 'Montserrat';
  margin: auto;
  text-align: center;
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 8px;
  line-height: 1.3;
`;

export const LeftHeader = styled.div`
  display: flex;
  justify-content: row;
  gap: 12px;
  padding: 7px;
  align-items: center;
  font-size: 16px;
`;

export const Text = styled.span`
  font-size: 12px;
  color: #444444;
`;
