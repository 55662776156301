import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import {
  getAudience,
  getOrgAudiences,
  saveAudience,
  updateAudience,
} from '../audiences';
import { queryClient } from '../../../infrastructure/queries';
import { selectedOrganizationSelector } from '../../selectors/organization';

export const useAudiencesQuery = ({ getChunkSize }, options) => {
  const activeOrganization = useSelector(selectedOrganizationSelector);

  const query = useInfiniteQuery(
    ['audiences', 'index'],
    async ({ pageParam: cursor = 0 }) => {
      const limit = getChunkSize(cursor); // compensate for the "new-card" in the 1st chunk

      const { result: audiences, left } = await getOrgAudiences({
        orgId: activeOrganization.id,
        skip: cursor,
        limit,
      });

      const result = { audiences };

      if (left > 0) {
        result.nextCursor = cursor + limit;
      }

      return result;
    },
    {
      ...options,
      getNextPageParam(page) {
        return page.nextCursor;
      },
    }
  );

  const data = query.data
    ? query.data.pages.flatMap(page => page.audiences)
    : [];
  return {
    ...query,
    data,
    isAnyPageLoaded: data.length > 0,
  };
};

export const useAudienceQuery = (id, options) => {
  return useQuery(['audiences', id], async () => getAudience(id), options);
};

export const useCreateAudienceMutation = options => {
  return useMutation(saveAudience, {
    ...options,
    onSuccess() {
      queryClient.invalidateQueries(['audiences', 'index']);
    },
  });
};

export const useUpdateAudienceMutation = options => {
  return useMutation(
    ({ id, updates }) => updateAudience({ ...updates, _id: id }),
    {
      ...options,
      onMutate({ id, updates }) {
        const audienceQueryKey = ['audiences', id];

        const previousAudience = queryClient.getQueryData(audienceQueryKey);
        const newAudience = { ...previousAudience, ...updates };
        queryClient.setQueryData(['audiences', id], newAudience);

        return { previousAudience };
      },
      onSuccess(_, { id }) {
        queryClient.invalidateQueries(['audiences', id]);
        queryClient.invalidateQueries(['audiences', 'index']);
      },
      onError(_, { id }, { previousAudience }) {
        queryClient.setQueryData(['audiences', id], previousAudience);
      },
    }
  );
};
