import moment from 'moment';

export const formatDate = saveTime => {
  if (!saveTime) return '';
  return moment.unix(saveTime).format('M/D/YY \\at h:mma');
};

export const formatTimestampToDate = (timestamp, showOnlyDate = false) => {
  if (!timestamp) return '';
  const timestampInSeconds = Math.floor(new Date(timestamp).getTime() / 1000);
  if (showOnlyDate) {
    return moment.unix(timestampInSeconds).format('M/D/YY');
  }

  return moment.unix(timestampInSeconds).format('M/D/YY \\at h:mma');
};

export const trimTextIfNeeded = (text, maxLength) => {
  if (!text) return '';
  return text.length > maxLength
    ? text.substring(0, maxLength - 3) + '...'
    : text;
};
