import styled, { css } from 'styled-components';
import { Input, Button } from 'antd';

export const DraftNameInput = styled(Input).attrs({ size: 'large' })`
  width: 170px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  text-overflow: ellipsis;
  border-radius: 5px;

  ${({ value = '' }) => {
    if (!value.trim()) {
      return null;
    }

    return css`
      &:hover:not(:focus) {
        cursor: pointer;
      }

      &:not(:focus):not(:hover) {
        border-color: transparent;
        background-color: transparent;
      }
    `;
  }}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 12px 24px;
  background-color: #ffffff;
  border-bottom: 1px solid #cccccc;
`;

Header.LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

Header.RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const createCTAControl = (BaseComponent, { color }) => {
  return styled(BaseComponent).attrs({ size: 'large' })`
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #${color};
    border: 1px solid #${color};
    border-radius: 5px;
    background-color: #${color}05;
    transition: background-color 0.3s;

    // overriding default antd styles for hovered state
    &,
    &:hover {
      border: 1px solid #${color};
      color: #${color};
    }

    &:hover {
      background-color: #${color}0f;
    }
  `;
};

const createCTAButton = options => {
  return styled(createCTAControl(Button, options))`
    > span + .anticon {
      font-size: 12px;
    }

    display: flex;
    justify-content: center;
    gap: 5px;
    ${options.paddingLeft ? `padding-left: ${options.paddingLeft};` : ''}
    ${options.paddingRight ? `padding-right: ${options.paddingRight};` : ''}
  `;
};

export const IconButton = createCTAButton({
  color: '007ade',
  paddingLeft: '9px',
  paddingRight: '9px',
});

export const NextButton = createCTAButton({ color: '5eab00' });
export const ActionButton = createCTAButton({ color: '007ade' });

export const SavingStatusText = styled.span`
  color: #6c7075;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  line-height: 12px;
  min-width: 80px;
`;

export const ScreenTitle = styled.h2`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0;
  margin-right: 10px;
  color: #555555;
  font-weight: 600;
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
`;

ScreenTitle.Text = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 1550px) {
    display: none;
  }
`;
