import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { _noop } from 'underscore-es';
import { ArrowForwardOutlined, Create } from '@material-ui/icons';
import { groupsSelector, topicsSelector } from '../../redux/selectors/tags';
import {
  formatTimestampToDate,
  trimTextIfNeeded,
} from '../Drafts/Components/_utils';
import {
  CardHeader,
  Button,
  ButtonGroup,
  RowSpaceBetween,
} from '../Drafts/Components/_CardUI/Card.styled';
import { Card, Container, Wrapper, StyledTag } from './AudienceCard.styled';

const useTags = audience => {
  const topics = useSelector(topicsSelector);
  const groups = useSelector(groupsSelector);
  const selectedMemberNames = Object.keys(audience.selectedMembers).map(
    memberId => audience.selectedMembers[memberId]
  );

  const selectedTopicsNames = audience.topicIds
    .map(topicId => {
      const selectedTopic = topics.find(topic => topic.id === topicId);
      return selectedTopic?.name;
    })
    .filter(name => name);

  const selectedGroupIds = [
    ...audience.selectedGroupIds,
    ...audience.requiredGroupIds,
    ...audience.excludedGroupIds,
  ];

  const selectedGroupsNames = selectedGroupIds.map(groupId => {
    const selectedGroup = groups.find(group => group.id === groupId);
    return selectedGroup?.name;
  });

  const selectedFiltersNames = audience.filterData
    ? Object.keys(audience.filterData)
    : [];

  return {
    topics: [...new Set(selectedTopicsNames)],
    groups: selectedGroupsNames,
    filters: selectedFiltersNames,
    members: selectedMemberNames,
  };
};

const useLabels = audience => {
  const { topics, groups, filters, members } = useTags(audience);
  const topicsTooltipText = topics?.map(topicName => (
    <div key={`${audience.name}-${topicName}`}>{topicName}</div>
  ));

  const groupsTooltipText = groups?.map(groupName => (
    <div key={`${audience.name}-${groupName}`}>{groupName}</div>
  ));

  const filtersTooltipText = filters?.map(filterName => (
    <div key={`${audience.name}-${filterName}`}>{filterName}</div>
  ));

  const membersTooltipText = members.map(({ action, name }) => (
    <div key={`${action}-${name}`}>{name}</div>
  ));

  const getName = (count, name) => {
    if (count > 1) return `${count} ${name}s`;
    return `${count} ${name}`;
  };

  return (
    <>
      {topics.length ? (
        <Tooltip title={topicsTooltipText}>
          <StyledTag>{getName(topics.length, 'Topic')}</StyledTag>
        </Tooltip>
      ) : null}
      {groups.length ? (
        <Tooltip title={groupsTooltipText}>
          <StyledTag>{getName(groups.length, 'Group')}</StyledTag>
        </Tooltip>
      ) : null}
      {filters.length ? (
        <Tooltip title={filtersTooltipText}>
          <StyledTag>{getName(filters.length, 'Filter')}</StyledTag>
        </Tooltip>
      ) : null}
      {members.length ? (
        <Tooltip title={membersTooltipText}>
          <StyledTag>{getName(members.length, 'Member')}</StyledTag>
        </Tooltip>
      ) : null}
    </>
  );
};

const AudienceCard = ({ audience, onClick = _noop, header = null, onEdit }) => {
  return (
    <Card>
      <CardHeader>{header}</CardHeader>
      <Card.Body>
        <Container>
          {audience.name ? (
            <Card.Name>{trimTextIfNeeded(audience.name, 100)}</Card.Name>
          ) : null}
          {audience.description ? (
            <Card.Description>
              Description: {trimTextIfNeeded(audience.description, 140)}
            </Card.Description>
          ) : null}
        </Container>
        <Wrapper>{useLabels(audience)}</Wrapper>
        <RowSpaceBetween>
          <Card.Date>
            Created: {formatTimestampToDate(audience.createdAt)} <br />
            Last updated: {formatTimestampToDate(audience.updatedAt)}
          </Card.Date>
          {onEdit ? (
            <ButtonGroup>
              <Tooltip title="Opens this Saved Audience for editing">
                <Button onClick={onEdit} light>
                  Edit <Create style={{ fontSize: '14px' }} />
                </Button>
              </Tooltip>
              <Tooltip title="Opens a new draft using this Saved Audience">
                <Button onClick={onClick}>
                  Use <ArrowForwardOutlined style={{ fontSize: '14px' }} />
                </Button>
              </Tooltip>
            </ButtonGroup>
          ) : (
            <Tooltip title="Apply this audience to the campaign">
              <Button onClick={onClick}>
                Apply <ArrowForwardOutlined style={{ fontSize: '14px' }} />
              </Button>
            </Tooltip>
          )}
        </RowSpaceBetween>
      </Card.Body>
    </Card>
  );
};

export default AudienceCard;
